import React from 'react';
import styled from 'styled-components';
import { Col, Container, Img, Row } from 'ui';
import { COLORS } from 'utils';
import { useQuery } from '@apollo/client';
import {
  GET_AMOUNT_OF_ARTICLES,
  GET_AMOUNT_OF_BUILDINGS,
  GET_AMOUNT_OF_NEWS
} from 'api/queries';

import arrow from 'assets/icons/arrow_pag.svg';

export const Pagination = ({ page, active, changeActive }) => {
  let query = '';
  if (page === 'news') {
    query = GET_AMOUNT_OF_NEWS;
  } else if (page === 'mass-media') {
    query = GET_AMOUNT_OF_ARTICLES;
  } else if (page === 'building') {
    query = GET_AMOUNT_OF_BUILDINGS;
  }

  const { data, error, loading } = useQuery(query);

  if (loading) {
    return <p>Loading...</p>;
  } else if (error) {
    return <p>Something went wrong...</p>;
  }

  let section = '';

  if (data && page === 'news') {
    section = data.news.data;
  } else if (data && page === 'mass-media') {
    section = data.articles.data;
  } else if (data && page === 'building') {
    section = data.buildings.data;
  }

  const numberOfItems = section.length;

  const totalPages = Math.ceil(numberOfItems / 5);

  const onChangeActive = (newActive) => {
    if (newActive > 0 && newActive <= totalPages) {
      changeActive(newActive);
    }
  };

  const onClickPrev = (newActive) => {
    if (newActive > 1) {
      changeActive(newActive - 1);
    }
  };

  const onClickNext = (newActive) => {
    if (newActive < totalPages) {
      changeActive(newActive + 1);
    }
  };

  if (totalPages <= 1) return null;

  return (
    <>
      <Container smdHidden>
        <Row nowrap justifyContent='center' gap={10}>
          <ArrowLeft
            disabled={active === 1}
            src={arrow}
            maxWidth={'48px'}
            onClick={() => onClickPrev(active)}
          />
          <Row gap={8} alignCenter>
            {Array.from({ length: totalPages }, (v, i) => i + 1).map(
              (item, key) => (
                <Col key={key}>
                  <PageButton
                    onClick={() => onChangeActive(item)}
                    active={item === active}
                  >
                    {item}
                  </PageButton>
                </Col>
              )
            )}
          </Row>
          <ArrowRight
            disabled={active === totalPages}
            src={arrow}
            maxWidth={'48px'}
            onClick={() => onClickNext(active)}
          />
        </Row>
      </Container>
    </>
  );
};

const ArrowLeft = styled(Img)`
  cursor: pointer;
  ${(p) => `
    ${
      p.disabled
        ? `
      opacity: .4;
      cursor:default;
    `
        : ''
    }
  `}
`;

const ArrowRight = styled(ArrowLeft)`
  transform: rotate(180deg);
`;

const PageButton = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: ${COLORS.blueActive};
  cursor: pointer;
  ${(p) =>
    ` ${
      p.active
        ? `
  color:${COLORS.white};
  background-color:${COLORS.blueActive};
  `
        : ''
    }`}
`;
