import React from 'react';
import styled from 'styled-components';
import { Container, fontMontserrat, Row } from 'ui';
import { BREAKPOINTS } from 'utils';

const collection = [
  {
    title: '10–20 м',
    text: 'Ширина полос движения'
  },
  {
    title: '120 км/час',
    text: 'Расчетная скорость движения '
  },
  {
    title: '< 40 000',
    text: 'Автомобилей в сутки'
  },
  {
    title: '4-6',
    text: 'Полос движения в обе стороны'
  },
  {
    title: 'до 25 %',
    text: 'Расчетная разгрузка МКАД'
  }
];

const Block = ({ title, text }) => {
  return (
    <StyledBlock height={'230px'}>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </StyledBlock>
  );
};

export const Info = (props) => {
  return (
    <Wrapper maxWidth={1200} {...props} justifyContent={'center'}>
      {collection.map((item, index) => (
        <div key={index}>
          <Block title={item.title} text={item.text} />
        </div>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled(Row)`
  margin: 0 auto;
  box-sizing: content-box;
  gap: 30px;
  @media (max-width: ${BREAKPOINTS.smd}px) {
    row-gap: 24px;
    column-gap: 11px;
  }
`;

const StyledBlock = styled(Container)`
  width: 216px;
  padding: 38px 16px;
  background: #ffffff;
  box-shadow: -12px 12px 20px rgba(7, 74, 135, 0.12);
  border-radius: 20px;
  @media (max-width: ${BREAKPOINTS.smd}px) {
    padding: 28px 16px;
    width: 159px;
    height: 180px;
  }
`;

const Title = styled.p`
  margin-bottom: 14px;
  ${fontMontserrat};
  font-weight: 500;
  font-size: 34px;
  line-height: 50px;
  color: #01509f;
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 23px;
  }
`;

const Text = styled.p`
  ${fontMontserrat};
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #23222f;
`;
