import React from 'react';
import styled from 'styled-components';
import { Img, Row, Text } from 'ui';
import { BREAKPOINTS, formatDateFromStrapi } from 'utils';

import date_icon from 'assets/icons/date.svg';

export const Date = ({ date }) => {
  return (
    <Row nowrap alignCenter>
      <Icon src={date_icon} maxWidth={'24px'} marginRight={10} />
      <Text color={'#929292'}>{formatDateFromStrapi(date)}</Text>
    </Row>
  );
};

const Icon = styled(Img)`
  @media (max-width: ${BREAKPOINTS.smd}px) {
    max-width: 20px;
  }
`;
