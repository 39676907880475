import React from 'react';
import { Header, Footer } from '../../components';
import {
  MainScreen,
  LearnMore,
  RoadLength,
  AboutProject,
  Benefits,
  Info,
  Partnership,
  Questions,
  Ecology,
  MassMedia
} from './sections';

export const MainPage = () => {
  return (
    <>
      <Header active='/' />

      <>
        <MainScreen />
        <LearnMore paddingTop={120} lgPaddingTop={80} smdPaddingTop={60} />
        <RoadLength paddingTop={120} lgPaddingTop={80} smdPaddingTop={60} />
        <AboutProject paddingTop={120} lgPaddingTop={80} smdPaddingTop={60} />
        <Benefits marginTop={120} lgMarginTop={80} smdMarginTop={60} />

        <Info paddingY={120} mdPaddingY={80} smdPaddingY={60} />

        <Partnership
          marginTop={120}
          mdMarginTop={80}
          smdMarginTop={60}
          marginBottom={60}
          mdMarginBottom={80}
          smdMarginBottom={60}
        />

        <Questions
          paddingY={60}
          mdPaddingY={80}
          smdPaddingY={60}
          paddingX={120}
          lgPaddingX={50}
          smdPaddingX={23}
        />

        <Ecology marginTop={60} lgMarginTop={80} smdMarginTop={60} />

        <MassMedia
          paddingTop={120}
          lgPaddingTop={80}
          smdPaddingTop={60}
          paddingBottom={240}
          lgPaddingBottom={200}
          smdPaddingBottom={180}
          paddingX={120}
          lgPaddingX={50}
          smdPaddingX={23}
        />
      </>

      <Footer active='/' />
    </>
  );
};
