import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import {
  GET_OTHER_ARTICLES,
  GET_OTHER_BUILDINGS,
  GET_OTHER_NEWS
} from 'api/queries';
import { Col, Container, fontMagra, H3, Img, Row, Text } from 'ui';
import { API_URL, BREAKPOINTS, COLORS, formatDateFromStrapi } from 'utils';
import { Link } from 'react-router-dom';
import { Date } from '../Date';
import { Source } from '../Source';

export const OtherArticles = ({ id, page }) => {
  const [limit, setLimit] = useState(3);

  let query = '';

  if (page === 'news') {
    query = GET_OTHER_NEWS;
  } else if (page === 'mass-media') {
    query = GET_OTHER_ARTICLES;
  } else if (page === 'building') {
    query = GET_OTHER_BUILDINGS;
  }

  const { loading, error, data } = useQuery(query, {
    variables: { id: id }
  });

  const dataQuery = useMemo(() => {
    if (data && page === 'news') {
      return data.news?.data;
    } else if (data && page === 'mass-media') {
      return data.articles?.data;
    } else if (data && page === 'building') {
      return data.buildings?.data;
    }

    return null;
  }, [data]);

  if (loading) {
    return <p>Loading...</p>;
  } else if (error === []) {
    return <p>Something went wrong...</p>;
  }

  const loadOtherArticles = () => {
    setLimit((prev) => prev + 3);
  };

  return (
    <Wrapper>
      <Container hidden lgVisible lgMarginBottom={40}>
        <StyledH3>Другие новости</StyledH3>
      </Container>
      <ContainerDesktop lgHidden>
        {dataQuery.slice(0, limit).map((item) => (
          <Row nowrap marginBottom={20} key={item.id}>
            <Col marginRight={16}>
              <Title marginBottom={10}>
                <Link
                  to={`/${page}/${item.id}`}
                >{`${item.attributes.title.substr(0, 35)}...`}</Link>
              </Title>
              <StyledDate>
                {formatDateFromStrapi(item.attributes.date)}
              </StyledDate>
            </Col>
            <Col maxWidth={'130px'}>
              <StyledImg
                wide
                src={`${API_URL}${item.attributes?.img?.data?.attributes?.url}`}
              />
            </Col>
          </Row>
        ))}
      </ContainerDesktop>

      <ContainerMobile hidden lgVisible>
        {dataQuery.slice(0, limit).map((item) => (
          <StyledRow lgMarginBottom={30} nowrap key={item.id}>
            <Col lgMarginRight={30}>
              <Title lgMarginBottom={10}>
                <Link to={`/${page}/${item.id}`}>{item.attributes.title}</Link>
              </Title>

              <Container hidden smdVisible smdMarginBottom={16}>
                <Row gap={10}>
                  <Date date={item.attributes.date} />
                  {item.attributes.link && (
                    <Source link={item.attributes.link} />
                  )}
                </Row>
              </Container>

              <Text lgMarginBottom={19} smdMarginBottom={16}>
                {item.attributes.description}
              </Text>
              <Container smdHidden>
                <Row gap={10}>
                  <Date date={item.attributes.date} />
                  {item.attributes.link && (
                    <Source link={item.attributes.link} />
                  )}
                </Row>
              </Container>
            </Col>

            <StyledMobileImg
              maxWidth={'250px'}
              src={`${API_URL}${item.attributes?.img?.data?.attributes?.url}`}
            />
          </StyledRow>
        ))}
      </ContainerMobile>

      {limit >= dataQuery.length ? null : (
        <Container>
          <Container smdHidden>
            <Line marginBottom={30} />
          </Container>

          <StyledText onClick={() => loadOtherArticles()}>
            Смотреть еще
          </StyledText>
        </Container>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Container)``;

const ContainerDesktop = styled(Container)``;

const ContainerMobile = styled(Container)``;

const StyledH3 = styled(H3)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 36px;
    line-height: 44px;
  }
`;

const StyledRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.smd}px) {
    flex-wrap: wrap;
  }
`;

const StyledImg = styled(Img)`
  height: 80px;
`;

const StyledMobileImg = styled(Img)`
  max-height: 220px;
  object-fit: cover;
  @media (max-width: ${BREAKPOINTS.smd}px) {
    max-width: unset;
    max-height: unset;
  }
`;

const Title = styled(Text)`
  &:hover {
    opacity: 0.6;
  }
  & > * {
    text-decoration: none;
    color: ${COLORS.default};
    ${fontMagra}
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    @media (max-width: ${BREAKPOINTS.lg}px) {
      text-transform: uppercase;
      font-size: 24px;
      line-height: 29px;
    }
    @media (max-width: ${BREAKPOINTS.smd}px) {
      font-size: 18px;
      line-height: 22px;
    }
  }
`;

const StyledDate = styled(Text)`
  color: #929292;
`;

const Line = styled(Container)`
  height: 1px;
  border-bottom: 1px solid #d9d8d8;
`;

const StyledText = styled(Link)`
  display: block;
  text-align: center;
  color: ${COLORS.blueActive};
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
  &:hover {
    opacity: 0.6;
  }
`;
