import React from 'react';
import styled from 'styled-components';
import { Container, H1, MainContainer } from 'ui';
import bg from 'assets/mainScreen/bg.jpg';
import { BREAKPOINTS } from 'utils';

export const MainScreen = () => {
  return (
    <>
      <Wrapper relative paddingTop={132} mdPaddingTop={92} smdPaddingTop={60}>
        <StyledMainContainer
          marginBottom={80}
          mdMarginBottom={60}
          smdMarginBottom={48}
        >
          <StyledH1>Лыткаринская платная дорога</StyledH1>
          <Text marginTop={40} smdMarginTop={36}>
            Масштабный проект системного улучшения дорожной инфраструктуры
            Подмосковья, предусматривающий строительство современной скоростной
            магистрали Солнцево – Бутово – Видное – Лыткарино – Томилино –
            Красково (ЛБВ-ЛТКЖ), известный также как «Южно-Лыткаринская
            автодорога» (ЮЛА).
          </Text>
        </StyledMainContainer>
      </Wrapper>
      <Bg src={bg} alt='' />
    </>
  );
};

const Wrapper = styled(Container)`
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
`;

const StyledMainContainer = styled(MainContainer)`
  @media (max-width: ${BREAKPOINTS.md}px) {
    max-width: unset;
    padding-left: 50px;
    padding-right: 50px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    padding-left: 23px;
    padding-right: 23px;
  }
`;

const StyledH1 = styled(H1)`
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 36px;
    line-height: 40px;
  }

  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 24px;
    line-height: 26px;
  }
`;

const Text = styled(Container)`
  max-width: 630px;
`;

const Bg = styled.img`
  width: 100%;
  display: block;
`;
