import {
  AboutProject,
  AllArticlesPage,
  ArticlePage,
  ContactsPage,
  MainPage,
  PDFPage
} from 'pages';
import { Navigate, Route, Routes as Switch } from 'react-router-dom';

export const Routes = () => {
  return (
    <Switch>
      <Route path='/' element={<MainPage />} />
      <Route path='/contacts' element={<ContactsPage page='contacts' />} />
      <Route
        path='/about-project'
        element={<AboutProject page='about-project' />}
      />
      <Route path='/shema' element={<PDFPage />} />
      <Route path='/news' element={<AllArticlesPage page='news' />} />
      <Route path='/news/:id' element={<ArticlePage page='news' />} />
      <Route
        path='/mass-media'
        element={<AllArticlesPage page='mass-media' />}
      />
      <Route
        path='/mass-media/:id'
        element={<ArticlePage page='mass-media' />}
      />
      <Route path='/building' element={<AllArticlesPage page='building' />} />
      <Route path='/building/:id' element={<ArticlePage page='building' />} />
      <Route path='*' element={<Navigate to='/' replace />} />
    </Switch>
  );
};
