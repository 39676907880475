import { Footer, Header } from 'components';
import React from 'react';
import { Benefits, Info, MainScreen, Slider } from './section';

export const AboutProject = ({ page }) => {
  return (
    <>
      <Header active={page} />
      <MainScreen />
      <Info
        paddingTop={120}
        xlPaddingX={32}
        xlPaddingTop={100}
        mdPaddingX={16}
        mdPaddingTop={60}
      />
      <Slider
        paddingTop={120}
        xlPaddingX={32}
        xlPaddingTop={80}
        mdPaddingX={16}
        mdPaddingTop={40}
      />
      <Benefits
        paddingTop={120}
        xlPaddingTop={80}
        mdPaddingTop={40}
        paddingBottom={240}
        xlPaddingBottom={120}
        mdPaddingBottom={80}
      />
      <Footer active={page} />
    </>
  );
};
