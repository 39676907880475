import React from 'react';
import styled from 'styled-components';
import { Container, H3, Img, Row } from 'ui';

import ill1 from 'assets/massMedia/vesti-logo.jpeg';
import ill3 from 'assets/massMedia/rbc-logo.png';
import ill4 from 'assets/massMedia/360_Black_NEWS.png';
import ill5 from 'assets/massMedia/logo-komsomolskaya-pravda.jpg';
import ill6 from 'assets/massMedia/maistro 3.png';
import ill9 from 'assets/massMedia/ill3.png';
import ill10 from 'assets/massMedia/ill10.png';
import ill11 from 'assets/massMedia/podmosk.png';
import ill12 from 'assets/massMedia/today.png';
import ill13 from 'assets/massMedia/panorama.png';
import ill14 from 'assets/massMedia/logo-russia-one 1.png';

import { BREAKPOINTS } from 'utils';

const NewsLink = ({ href, img }) => {
  return (
    <a rel='noreferrer' href={href} target='_blank'>
      <Ill wide src={img} />
    </a>
  );
};

export const MassMedia = (props) => {
  return (
    <Wrapper {...props} maxWidth={1440}>
      <H3 marginBottom={60} smdMarginBottom={36}>
        СМИ о нас
      </H3>
      <Container lgHidden>
        <StyledRow
          justifyContent={'space-around'}
          alignCenter
          nowrap
          marginBottom={70}
          smdMarginBottom={1}
        >
          <NewsLink
            href='https://companies.rbc.ru/news/iClQCtybO1/yuzhno-lyitkarinskaya-doroga-realnost-interesnee-mifov/'
            img={ill14}
          />
          <NewsLink
            href='https://companies.rbc.ru/news/iClQCtybO1/yuzhno-lyitkarinskaya-doroga-realnost-interesnee-mifov/'
            img={ill3}
          />

          <NewsLink
            href='https://vmo24.ru/news/kak_v_podmoskove_idet_stroitelstvo_yuzhno-lytkarinskoy_avtodorogi'
            img={ill1}
          />

          <NewsLink
            href='https://360tv.ru/news/mosobl/kak-novyj-dubler-razgruzit-mkad-i-magistrali-novolytkarinskoe-shosse-izbavit-podmoskove-ot-probok/'
            img={ill4}
          />
        </StyledRow>

        <StyledRow
          justifyContent={'space-between'}
          alignCenter
          nowrap
          marginBottom={70}
          smdMarginBottom={1}
        >
          <NewsLink
            href='https://stroygaz.ru/news/info-partners-news/stroitelstvo-yuzhno-lytkarinskoy-avtomobilnoy-dorogi-magistral-v-budushchee/'
            img={ill10}
          />

          <NewsLink
            href='https://www.mosobl.kp.ru/online/news/4935441/'
            img={ill5}
          />
          <NewsLink
            href='https://inlubertsy.ru/news/stroitelstvo/gubernator-moskovskoj-oblasti-andrej-vorobev-osmotrel-stroitelstvo-juzhno-lytkarinskoj-avtodorogi-jula'
            img={ill13}
          />

          <NewsLink
            href='https://maistro.ru/articles/transport/stroitelstvo-yuzhno-lytkarkinskoj-avtomobilnoj-dorogi-magistral-v-budushhee'
            img={ill6}
          />
        </StyledRow>

        <StyledRow
          justifyContent={'space-around'}
          alignCenter
          nowrap
          marginBottom={70}
          smdMarginBottom={1}
        >
          <NewsLink
            href='https://mosregtoday.ru/news/econbiz/kak-stroitelstvo-novolytkarinskogo-shosse-povliyaet-na-ekologiyu/'
            img={ill11}
          />

          <NewsLink
            href='https://in-vidnoe.ru/news/ekonomika_i_biznes/proletet-po-podmoskovyu-yuzhno-lytkarinskaya-avtodoroga-budet-gotova-k-2025-godu'
            img={ill12}
          />

          <NewsLink
            href='https://riamo.ru/article/492577/yuzhnyj-dubler-mkad-uluchshit-transportnuyu-dostupnost-gorodov-v-blizhnem-podmoskove-xl'
            img={ill9}
          />
        </StyledRow>
      </Container>

      <Container hidden lgVisible>
        <StyledRow
          spaceBetween
          alignCenter
          nowrap
          marginBottom={70}
          smdMarginBottom={1}
        >
          <NewsLink
            href='https://companies.rbc.ru/news/iClQCtybO1/yuzhno-lyitkarinskaya-doroga-realnost-interesnee-mifov/'
            img={ill14}
          />

          <NewsLink
            href='https://companies.rbc.ru/news/iClQCtybO1/yuzhno-lyitkarinskaya-doroga-realnost-interesnee-mifov/'
            img={ill3}
          />

          <NewsLink
            href='https://vmo24.ru/news/kak_v_podmoskove_idet_stroitelstvo_yuzhno-lytkarinskoy_avtodorogi'
            img={ill1}
          />

          <NewsLink
            href='https://360tv.ru/news/mosobl/kak-novyj-dubler-razgruzit-mkad-i-magistrali-novolytkarinskoe-shosse-izbavit-podmoskove-ot-probok/'
            img={ill4}
          />
        </StyledRow>

        <StyledRow
          justifyContent={'space-around'}
          alignCenter
          nowrap
          marginBottom={70}
          smdMarginBottom={1}
        >
          <NewsLink
            href='https://stroygaz.ru/news/info-partners-news/stroitelstvo-yuzhno-lytkarinskoy-avtomobilnoy-dorogi-magistral-v-budushchee/'
            img={ill10}
          />

          <NewsLink
            href='https://www.mosobl.kp.ru/online/news/4935441/'
            img={ill5}
          />
        </StyledRow>

        <StyledRow
          spaceBetween
          alignCenter
          nowrap
          marginBottom={70}
          smdMarginBottom={1}
        >
          <NewsLink
            href='https://inlubertsy.ru/news/stroitelstvo/gubernator-moskovskoj-oblasti-andrej-vorobev-osmotrel-stroitelstvo-juzhno-lytkarinskoj-avtodorogi-jula'
            img={ill13}
          />

          <NewsLink
            href='https://maistro.ru/articles/transport/stroitelstvo-yuzhno-lytkarkinskoj-avtomobilnoj-dorogi-magistral-v-budushhee'
            img={ill6}
          />

          <NewsLink
            href='https://mosregtoday.ru/news/econbiz/kak-stroitelstvo-novolytkarinskogo-shosse-povliyaet-na-ekologiyu/'
            img={ill11}
          />
        </StyledRow>

        <StyledRow justifyContent={'space-around'} alignCenter nowrap>
          <NewsLink
            href='https://in-vidnoe.ru/news/ekonomika_i_biznes/proletet-po-podmoskovyu-yuzhno-lytkarinskaya-avtodoroga-budet-gotova-k-2025-godu'
            img={ill12}
          />

          <NewsLink
            href='https://riamo.ru/article/492577/yuzhnyj-dubler-mkad-uluchshit-transportnuyu-dostupnost-gorodov-v-blizhnem-podmoskove-xl'
            img={ill9}
          />
        </StyledRow>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  margin-left: auto;
  margin-right: auto;
`;

const StyledRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.smd}px) {
    flex-direction: column;
  }
`;

const Ill = styled(Img)`
  max-width: 190px;
  width: 100%;
  max-height: 62px;
  ${(p) => (p.black ? 'background-color: black;' : '')}
  @media (max-width: ${BREAKPOINTS.lg}px) {
    max-width: 171px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    max-width: 152px;
    margin-bottom: 40px;
  }
`;
