import React, { useRef } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import { Col, Container, Img, MainContainer, Row, Text } from 'ui';
import { BREAKPOINTS, COLORS } from 'utils';
import bg from 'assets/aboutProject/bg.png';
import bgTablet from 'assets/aboutProject/bg-tablet.png';
import icon from 'assets/aboutProject/icon.png';
import icon2 from 'assets/aboutProject/icon2.png';
import icon3 from 'assets/aboutProject/icon3.png';
import icon4 from 'assets/aboutProject/icon4.png';
import arrow_slider from 'assets/aboutProject/arrow_slider.svg';

const info = [
  {
    img: icon,
    text: 'Быстро, безопасно, без пробок! Магистраль предусматривает безопасное и безбарьерное движение со скоростью до 120 км/час. Это позволит сократить время в пути между населёнными пунктами юга и юго-востока Московской области в 2,5 – 3 раза, и гарантирует отсутствие пробок.'
  },
  {
    img: icon2,
    text: 'Инфраструктурный мега-проект с перспективой на будущее! Благодаря новой магистрали снизятся логистические издержки, появятся новые пути доставки товаров, расширятся возможности для создания новых малых и средних предприятий, что даст толчок к развитию всей юго-восточной агломерации.'
  },
  {
    img: icon3,
    text: 'Москва – рядом! Невероятная транспортная доступность: Южно-Лыткаринская автодорога позволит жителям нескольких подмосковных городских округов получить реальную возможность доезжать до столицы менее чем за 1,5 часа, а время проезда до конечных станций метро сократится до рекордных 15-20 минут.'
  },
  {
    img: icon4,
    text: 'Экология, эстетика, забота о людях! Магистраль стоится по проекту, учитывающему географические, социально-культурные и природные особенности юго-востока Подмосковья. Дорога создается под нужды и потребности жителей, с учётом их пожеланий и соблюдением всех стандартов современного градостроительного законодательства.'
  }
];

export const AboutProject = (props) => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <Wrapper {...props}>
      <BGTablet paddingBottom={68}>
        <StyledMainContainer>
          <StyledTitle
            marginBottom={60}
            smdMarginBottom={48}
            mdMarginLeft={23}
            smMarginLeft={3}
            color={COLORS.blueH}
          >
            О проекте
          </StyledTitle>

          <Container smdHidden>
            <Row gap={40} xlGap={45} colsMB={40} justifyContent={'center'}>
              {info.map((item, index) => (
                <Col key={index} maxWidth={'240px'}>
                  <Img src={item.img} alt='' />
                  <Text>{item.text}</Text>
                </Col>
              ))}
            </Row>
          </Container>

          <MobileContainer
            smdMarginLeft={23}
            smMarginLeft={3}
            hidden
            smdVisible
          >
            <StyledSwiper
              modules={[Navigation]}
              spaceBetween={36}
              slidesPerView={'auto'}
              autoHeight
              loop={true}
              onInit={(swiper) => {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
                swiper.navigation.init();
                swiper.navigation.update();
              }}
            >
              {info.map((item, index) => (
                <StyledSlide key={index}>
                  <Col maxWidth={'240px'}>
                    <Img src={item.img} alt='' />
                    <StyledText>{item.text}</StyledText>
                  </Col>
                </StyledSlide>
              ))}
              <NavNextEl ref={navigationNextRef}>
                <NavImg src={arrow_slider} />
              </NavNextEl>
            </StyledSwiper>
          </MobileContainer>
        </StyledMainContainer>
      </BGTablet>
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  background: url('${bg}') bottom left no-repeat;
  background-size: contain;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    background: none;
  }
`;

const BGTablet = styled(Container)`
  @media (max-width: ${BREAKPOINTS.xl}px) {
    background: url('${bg}') bottom left no-repeat;
    background-size: cover;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    background: url('${bgTablet}') bottom left no-repeat;
    background-size: cover;
  }
`;

const StyledMainContainer = styled(MainContainer)`
  @media (max-width: ${BREAKPOINTS.xl}px) {
    max-width: 700px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    padding-right: 0px;
  }
`;

const StyledTitle = styled(Text)`
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 36px;
    line-height: 40px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 24px;
    line-height: 26px;
  }
`;

const StyledText = styled(Text)`
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const MobileContainer = styled(Container)``;

const StyledSwiper = styled(Swiper)`
  .swiper-slide {
    max-width: 240px;
  }
`;

const StyledSlide = styled(SwiperSlide)``;

const NavNextEl = styled.div`
  z-index: 10;
  position: absolute;
  width: 46px;
  height: 356px;
  right: 0px;
  top: 0px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.04) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  backdrop-filter: blur(4px);
  cursor: pointer;
  :active {
    opacity: 0.6;
  }
`;

const NavImg = styled.img`
  position: absolute;
  top: calc(50% - 40px);
  right: 0;
  z-index: 10;
  cursor: pointer;
  :active {
    opacity: 0.6;
  }
`;
