import React from 'react';
import styled from 'styled-components';
import { Container, Img } from 'ui';
import ill from 'assets/benefits/ill.jpg';

export const Benefits = (props) => {
  return (
    <Wrapper {...props}>
      <Img src={ill} wide />
    </Wrapper>
  );
};

const Wrapper = styled(Container)``;
