import React, { useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

// import samplePDF from './Shema.pdf';

export const PDFPage = () => {
  // useEffect(() => { pdfjs.GlobalWorkerOptions.workerSrc =`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;});
  // return (
  //   <Document file={samplePDF}>
  //     <Page pageNumber={1} />
  //   </Document>
  // );
};
