import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
  Date,
  Footer,
  Header,
  Loader,
  Navigation,
  OtherArticles,
  Source
} from 'components';
import { Col, Container, fontMagra, Img, Row, Text } from 'ui';
import { BREAKPOINTS, getImageUrl } from 'utils';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';
import { GET_ARTICLE, GET_BUILDING, GET_NEWS } from 'api/queries';
import ReactMarkdown from 'react-markdown';

export const ArticlePage = ({ page }) => {
  const { id } = useParams();

  let query = '';

  switch (page) {
    case 'news':
      query = GET_NEWS;
      break;
    case 'mass-media':
      query = GET_ARTICLE;
      break;
    case 'building':
      query = GET_BUILDING;
      break;
    default:
      query = null;
  }

  const { loading, error, data } = useQuery(query, {
    variables: { id: id }
  });

  const dataQuery = useMemo(() => {
    if (data && page === 'news') {
      return data.new?.data;
    } else if (data && page === 'mass-media') {
      return data.article?.data;
    } else if (data && page === 'building') {
      return data.building?.data;
    }

    return null;
  }, [data]);

  if (loading) {
    return (
      <Row justifyContent={'center'} wide alignCenter height={'600px'}>
        <Loader />
      </Row>
    );
  }

  const imgUrl = getImageUrl(dataQuery.attributes.img?.data?.attributes?.url);
  if (dataQuery && !error) {
    return (
      <>
        <Header active={page} />

        <Wrapper
          maxWidth={1200}
          paddingX={15}
          lgPaddingX={50}
          smdPaddingX={23}
          paddingTop={63}
          paddingBottom={240}
          lgPaddingTop={53}
          smdPaddingTop={60}
          smdPaddingBottom={234}
        >
          <Container smdHidden marginBottom={51} lgMarginBottom={40}>
            <Navigation page={page} title={dataQuery.attributes.title} />
          </Container>

          <Container lgMarginBottom={60} smdMarginBottom={48}>
            <StyledCol maxWidth={792}>
              <Title marginBottom={16} smdMarginBottom={12}>
                {dataQuery.attributes.title}
              </Title>
            </StyledCol>

            <Row nowrap gap={12} marginBottom={40}>
              <Date date={dataQuery.attributes.date} />
              {dataQuery.attributes.link && (
                <Source link={dataQuery.attributes.link} />
              )}
            </Row>

            <Row nowrap>
              <StyledCol maxWidth={792} marginRight={24} lgMarginRight={1}>
                <Description marginBottom={30}>
                  {dataQuery.attributes.description}
                </Description>

                <Img src={imgUrl} wide marginBottom={30} />

                <TextContent>
                  {dataQuery.attributes.section[0]?.text}
                </TextContent>
              </StyledCol>

              <Col>
                <ContainerDesktop lgHidden>
                  <OtherArticles id={id} page={page} />
                </ContainerDesktop>
              </Col>
            </Row>
          </Container>

          <ContainerTablet hidden lgVisible>
            <OtherArticles id={id} page={page} />
          </ContainerTablet>
        </Wrapper>

        <Footer active={page} />
      </>
    );
  }

  return <p>Something went wrong...</p>;
};

const Wrapper = styled(Container)`
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 750px) {
    padding-right: 15px;
    padding-left: 15px;
  }
`;

const ContainerDesktop = styled(Container)``;

const ContainerTablet = styled(Container)``;

const StyledCol = styled(Col)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    max-width: unset;
  }
`;

const Title = styled(Text)`
  ${fontMagra}
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

const Description = styled(Text)`
  font-weight: 500;
`;

const TextContent = styled(ReactMarkdown)`
  & > * {
    margin-bottom: 20px;
  }
  & :last-child {
    margin-bottom: 0;
  }
`;
