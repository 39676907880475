import React, { useRef } from 'react';
import styled from 'styled-components';
import { Col, Container, fontMontserrat, H2, Img, Row, Text } from 'ui';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper';
import 'swiper/css';
import arrow_nav from 'assets/icons/arrow_nav.svg';
import ill1 from 'assets/slider/image01.png';
import ill2 from 'assets/slider/image02.png';
import ill3 from 'assets/slider/image03.png';
import { BREAKPOINTS } from 'utils';
import { Link } from 'react-router-dom';

export const Slider = (props) => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <Container {...props}>
      <StyledH2>О проекте</StyledH2>
      <StyledRow
        maxWidth={1200}
        justifyContent={'center'}
        nowrap
        alignItems={'center'}
      >
        <StyledCol width={'570px'}>
          <StyledText marginBottom={24}>
            Платная высокоскоростная магистраль – дублёр МКАД, проект,
            реализуемый в рамках Концессионного соглашения, заключенного между
            Правительством Московской области и ООО «Лыткаринская платная
            дорога».
          </StyledText>

          <StyledText marginBottom={36}>
            Согласно условиям Концессионного соглашения инвестор разрабатывает
            проект, обеспечивает строительство и осуществляет последующую
            эксплуатацию автодороги на платной основе в течение 29 лет, после
            чего трасса будет передана в собственность Московской области.
          </StyledText>

          <StyledLink to='/Shema.pdf' target='_blank' download>
            Скачать схему дороги
          </StyledLink>
        </StyledCol>

        <StyledCol width={'570px'}>
          <StyledSwiper
            autoplay={{
              delay: 2500,
              disableOnInteraction: false
            }}
            modules={[Autoplay, Navigation]}
            spaceBetween={36}
            slidesPerView={'auto'}
            autoHeight
            loop={true}
            onInit={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
            }}
          >
            <StyledSlide>
              <Img src={ill1} />
            </StyledSlide>
            <StyledSlide>
              <Img src={ill2} />
            </StyledSlide>
            <StyledSlide>
              <Img src={ill3} />
            </StyledSlide>
            <NavPrevEl ref={navigationPrevRef}>
              <NavImg src={arrow_nav} />
            </NavPrevEl>
            <NavNextEl ref={navigationNextRef}>
              <NavImg src={arrow_nav} />
            </NavNextEl>
          </StyledSwiper>
        </StyledCol>
      </StyledRow>
    </Container>
  );
};

const StyledH2 = styled(H2)`
  font-size: 36px;
  text-align: center;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 36px;
    line-height: 44px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

const StyledRow = styled(Row)`
  margin: 60px auto 0;
  box-sizing: content-box;
  column-gap: 60px;
  row-gap: 30px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    flex-direction: column;
    margin-top: 30px;
  }
`;

const StyledCol = styled(Col)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    max-width: unset;
  }
`;

const StyledText = styled(Text)`
  font-size: 24px;
  line-height: 31px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  ${fontMontserrat};
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #106eb7;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 20px;
    line-height: 30px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 18px;
  }
`;

const StyledSwiper = styled(Swiper)`
  .swiper-slide {
    max-width: 570px;
    @media (max-width: ${BREAKPOINTS.lg}px) {
      max-width: unset;
      & img {
        width: 100%;
      }
    }
  }
`;

const StyledSlide = styled(SwiperSlide)``;

const NavNextEl = styled.div`
  z-index: 10;
  position: absolute;
  width: 40px;
  height: 40px;
  right: 8px;
  top: calc(50% - 20px);
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(40px);
  border-radius: 100px;
  cursor: pointer;
  :active {
    opacity: 0.6;
  }
`;

const NavPrevEl = styled(NavNextEl)`
  right: unset;
  left: 8px;
  transform: rotate(0.5turn);
`;

const NavImg = styled.img`
  position: absolute;
  width: 40px;
  height: 40px;
  top: 0;
  right: 0;
  z-index: 20;
  cursor: pointer;
  :active {
    opacity: 0.6;
  }
`;
