import React from 'react';
import styled from 'styled-components';
import { fontMontserrat } from 'ui/typography';
import { BREAKPOINTS } from '../../../utils';

export const Button = ({ title = '', smNoRadius = false, size = '' }) => {
  return (
    <StyledButton smNoRadius={smNoRadius} size={size}>
      {title}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  height: 54px;
  background: #fab43a;
  padding: 16px 36px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  transition: 0.1s;
  cursor: pointer;
  ${fontMontserrat}
  :hover {
    background: #106eb7;
  }
  :active {
    background: #074a87;
  }

  ${(p) =>
    p.size === 'medium'
      ? `
      height: 59px;
      font-size: 17px;
      line-height: 21px;
    `
      : ''}

  @media (max-width: ${BREAKPOINTS.md}px) {
    height: 46px;
    padding: 12px 14px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    height: 44px;
    padding: 12px 16px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: unset;
    ${(p) => (p.smNoRadius ? 'border-radius: unset;' : '')}
  }
`;
