import React from 'react';
import styled from 'styled-components';
import { Container, H2, Img, Row, Text } from 'ui';
import ill from 'assets/benefitsProject/ill.jpg';
import mark from 'assets/benefitsProject/Vector.svg';
import shadow from 'assets/benefitsProject/shadow.png';
import bg from 'assets/benefitsProject/map.jpg';
import { BREAKPOINTS } from 'utils';

const collection = [
  { text: 'Система безбарьерного\nдвижения' },
  {
    text: '2 новых въезда в Москву (по\nЮго-восточной и Северо-\nвосточной хордам)'
  },
  {
    text: 'Сокращение в 2,5 раза времени\nв пути от населенных пунктов\nобласти и до аэропортов '
  },
  {
    text: 'Увеличение стоимости\n существующего жилья'
  },
  {
    text: 'Привлечение дополнительных\nинвестиций в строительны\nкластер Подмосковья'
  },
  {
    text: 'Увеличение до 50 % скорости\nпередвижения между\nнаселенными пунктами'
  },
  {
    text: 'Создание новых рабочих мест'
  },
  {
    text: 'Два новых въезда в Москву (по ЮВХ и СВХ)'
  }
];

export const Benefits = (props) => {
  return (
    <>
      <Wrapper {...props} maxWidth={1440} smdHidden>
        <Img src={ill} wide />
      </Wrapper>

      <Container hidden smdVisible paddingTop={60} paddingBottom={240}>
        <BG>
          <Shadow>
            <Container smdPaddingX={23} smdPaddingY={40}>
              <StyledH2>Преимущества</StyledH2>
              <StyledRow
                direction={'column'}
                justifyContent={'center'}
                marginTop={30}
              >
                {collection.map((item, index) => (
                  <Row key={index} alignCenter nowrap marginBottom={26} gap={6}>
                    <ImgBlock>
                      <Img src={mark} />
                    </ImgBlock>
                    <StyledText>{item.text}</StyledText>
                  </Row>
                ))}
              </StyledRow>
            </Container>
          </Shadow>
        </BG>
      </Container>
    </>
  );
};

const Wrapper = styled(Container)`
  margin: 0 auto;
  box-sizing: content-box;
`;

const BG = styled.div`
  width: 100%;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  /* @media (max-width: 400px) {
    background-size: contain;
  } */
`;

const Shadow = styled(BG)`
  background-image: url(${shadow});
`;

const StyledH2 = styled(H2)`
  text-align: center;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 36px;
    line-height: 44px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

const StyledText = styled(Text)`
  max-width: 280px;
  white-space: break-spaces;
`;

const StyledRow = styled(Row)`
  & :last-child {
    margin-bottom: 0;
  }
`;

const ImgBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(7, 74, 135, 0.12);
  border-radius: 100px;
`;
