import { gql } from '@apollo/client';
import { ARTICLE_FIELDS, BUILDING_FIELDS, NEWS_FIELDS } from './fragments';

export const CREATE_EMAIL = gql`
  mutation createEmail($text: String) {
    createEmail(data: { email: $text }) {
      data {
        attributes {
          email
        }
      }
    }
  }
`;

export const GET_AMOUNT_OF_NEWS = gql`
  query GetAmountOfNews {
    news(pagination: { limit: -1 }) {
      data {
        id
      }
    }
  }
`;

export const GET_ALL_NEWS = gql`
  ${NEWS_FIELDS}
  query GetAllNews($start: Int!, $limit: Int!) {
    news(sort: "date:desc", pagination: { limit: $limit, start: $start }) {
      data {
        id
        attributes {
          ...NewsFields
        }
      }
    }
  }
`;

export const GET_NEWS = gql`
  ${NEWS_FIELDS}
  query GetNews($id: ID) {
    new(id: $id) {
      data {
        id
        attributes {
          ...NewsFields
          section {
            ... on ComponentSectionSelect {
              text
            }
          }
        }
      }
    }
  }
`;

export const GET_OTHER_NEWS = gql`
  ${NEWS_FIELDS}
  query GetOtherNews($id: ID) {
    news(
      sort: "date:desc"
      pagination: { limit: -1 }
      filters: { id: { notContains: $id } }
    ) {
      data {
        id
        attributes {
          ...NewsFields
        }
      }
    }
  }
`;

export const GET_AMOUNT_OF_ARTICLES = gql`
  query GetAmountOfArticles {
    articles(pagination: { limit: -1 }) {
      data {
        id
      }
    }
  }
`;

export const GET_ALL_ARTICLES = gql`
  ${ARTICLE_FIELDS}
  query GetAllArticles($start: Int!, $limit: Int!) {
    articles(sort: "date:desc", pagination: { limit: $limit, start: $start }) {
      data {
        id
        attributes {
          ...ArticleFields
        }
      }
    }
  }
`;

export const GET_ARTICLE = gql`
  ${ARTICLE_FIELDS}
  query GetArticle($id: ID) {
    article(id: $id) {
      data {
        id
        attributes {
          ...ArticleFields
          section {
            ... on ComponentSectionSelect {
              text
            }
          }
        }
      }
    }
  }
`;

export const GET_OTHER_ARTICLES = gql`
  ${ARTICLE_FIELDS}
  query GetOtherArticles($id: ID) {
    articles(
      sort: "date:desc"
      pagination: { limit: -1 }
      filters: { id: { notContains: $id } }
    ) {
      data {
        id
        attributes {
          ...ArticleFields
        }
      }
    }
  }
`;

export const GET_AMOUNT_OF_BUILDINGS = gql`
  query GetAmountOfBuildings {
    buildings(pagination: { limit: -1 }) {
      data {
        id
      }
    }
  }
`;

export const GET_ALL_BUILDINGS = gql`
  ${BUILDING_FIELDS}
  query GetAllBuildings($start: Int!, $limit: Int!) {
    buildings(sort: "date:desc", pagination: { limit: $limit, start: $start }) {
      data {
        id
        attributes {
          ...BuildingFields
        }
      }
    }
  }
`;

export const GET_BUILDING = gql`
  ${BUILDING_FIELDS}
  query GetBuilding($id: ID) {
    building(id: $id) {
      data {
        id
        attributes {
          ...BuildingFields
          section {
            ... on ComponentSectionSelect {
              text
            }
          }
        }
      }
    }
  }
`;

export const GET_OTHER_BUILDINGS = gql`
  ${BUILDING_FIELDS}
  query GetOtherBuildings($id: ID) {
    buildings(
      sort: "date:desc"
      pagination: { limit: -1 }
      filters: { id: { notContains: $id } }
    ) {
      data {
        id
        attributes {
          ...BuildingFields
        }
      }
    }
  }
`;
