import { gql } from '@apollo/client';

export const ARTICLE_FIELDS = gql`
  fragment ArticleFields on Article {
    title
    description
    date
    link
    img {
      data {
        attributes {
          url
        }
      }
    }
  }
`;

export const NEWS_FIELDS = gql`
  fragment NewsFields on New {
    title
    description
    date
    img {
      data {
        attributes {
          url
        }
      }
    }
  }
`;

export const BUILDING_FIELDS = gql`
  fragment BuildingFields on Building {
    title
    description
    date
    img {
      data {
        attributes {
          url
        }
      }
    }
  }
`;
