import { API_URL } from 'utils';

const addZero = (val) => {
  return `0${val}`.slice(-2);
};

export const formatDate = (date) => {
  if (date)
    return `${addZero(date.getDate())}.${addZero(
      date.getMonth() + 1
    )}.${date.getFullYear()}`;

  return '';
};

export const formatDateFromStrapi = (date) => {
  if (date) return date.split('-').reverse().join('.');

  return '';
};

export const getImageUrl = (url) => {
  return `${API_URL}${url}`;
};
