import React from 'react';
import styled from 'styled-components';
import { Img, Link } from 'ui';
import { BREAKPOINTS, COLORS } from 'utils';

import source_icon from 'assets/icons/source.svg';

export const Source = ({ link }) => {
  return (
    <StyledLink href={link} color={COLORS.blueActive}>
      <Icon src={source_icon} maxWidth={'24px'} marginRight={10} />
      Источник
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  &:hover {
    opacity: 0.6;
  }
`;

const Icon = styled(Img)`
  @media (max-width: ${BREAKPOINTS.smd}px) {
    max-width: 20px;
  }
`;
