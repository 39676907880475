import { createGlobalStyle } from 'styled-components';
import { BREAKPOINTS, COLORS } from './utils';

export const GlobalStyles = createGlobalStyle`  
  * {
    box-sizing: border-box;
  }
  html {
    overflow-x: hidden;
  }
  body {
    margin: 0;
    background: #FFFFFF;
    font-family: 'Montserrat', sans-serif;
    color: ${COLORS.default};
    overflow-x: hidden;
    font-size: 16px;
    line-height: 20px;
    
    &::-webkit-scrollbar {
  display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

    /* @media (max-width: ${BREAKPOINTS.md}px) {
      font-size: 16px;
      line-height: 20px;
    } 
     @media (max-width: ${BREAKPOINTS.sm}px) {
      font-size: 12px;
      line-height: 16px;
    } */
  }
  
  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }
`;
