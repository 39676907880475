import React from 'react';
import styled from 'styled-components';
import { Col, Container, Img, Row, Text } from 'ui';
import { COLORS } from 'utils';
import ill from 'assets/roadLength/ill.jpg';
import ill_mobile from 'assets/roadLength/bg-mobile.png';
import icon from 'assets/roadLength/icon.png';
import icon2 from 'assets/roadLength/icon2.png';
import icon3 from 'assets/roadLength/icon3.png';
import icon4 from 'assets/roadLength/icon4.png';

const info = [
  {
    img: icon,
    title: '10-20 м',
    descr: 'Ширина полос движения'
  },
  {
    img: icon2,
    title: 'от 40 000',
    descr: 'Автомобилей в сутки'
  },
  {
    img: icon3,
    title: '120 км/час',
    descr: 'Расчетная скорость движения'
  },
  {
    img: icon4,
    title: '4-6',
    descr: 'Полос движения с разделительной полосой'
  }
];

export const RoadLength = (props) => {
  return (
    <Wrapper {...props}>
      <ContainerDesktop smdHidden>
        <Img src={ill} alt='road-length' maxWidth={1440} />
      </ContainerDesktop>

      <ContainerMobile hidden smdVisible>
        <Row
          marginBottom={48}
          direction={'column'}
          alignCenter
          justifyContent={'center'}
        >
          {info.map((item, index) => (
            <Row key={index} gap={6} marginBottom={36}>
              <Col>
                <Img src={item.img} />
              </Col>
              <Col>
                <StyledTitle color={COLORS.blueH} marginBottom={6}>
                  {item.title}
                </StyledTitle>
                <StyledText>{item.descr}</StyledText>
              </Col>
            </Row>
          ))}
        </Row>

        <Img wide src={ill_mobile} alt='' />
      </ContainerMobile>
    </Wrapper>
  );
};

const Wrapper = styled(Container)``;

const ContainerDesktop = styled(Container)``;

const ContainerMobile = styled(Container)``;

const StyledTitle = styled(Text)`
  font-weight: 600;
  font-size: 36px;
  line-height: 130%;
`;

const StyledText = styled(Text)`
  width: 190px;
  font-size: 16px;
  line-height: 21px;
`;
