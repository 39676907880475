import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Container, Img, Row } from 'ui';
import { BREAKPOINTS, COLORS } from 'utils';

import arrow from 'assets/icons/arrow_nav.svg';

export const Navigation = ({ page, title }) => {
  let section = '';
  if (page === 'news') section = 'Новости';
  else if (page === 'mass-media') section = 'СМИ о нас';
  else if (page === 'building') section = 'Ход строительства';
  else if (page === 'contacts') section = 'Контакты';

  return (
    <Row nowrap gap={4} alignItems='flex-start'>
      <Text active>
        <Link to='/'>Главная</Link>
      </Text>
      <StyledImg src={arrow} maxWidth={'18px'} />
      <Text title={title}>
        <Link to={`/${page}`}>{section}</Link>
      </Text>
      {title && (
        <>
          <StyledImg src={arrow} maxWidth={'18px'} />
          <FlexContainer>
            <Text>{title.substr(0, 100)}...</Text>
          </FlexContainer>

          <ContainerTablet hidden lgVisible>
            <Text>{`${title.substr(0, 38)}...`}</Text>
          </ContainerTablet>
        </>
      )}
    </Row>
  );
};

const ContainerTablet = styled(Container)``;

const Text = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: #929292;
  &:hover {
    opacity: 0.6;
  }
  & > * {
    white-space: nowrap;
    text-decoration: none;
    color: #929292;
    ${(p) =>
      p.active || p.title
        ? `
      color: ${COLORS.blueActive}
      `
        : ''};
  }
`;

const StyledImg = styled(Img)`
  height: 16px;
`;

const FlexContainer = styled.div`
  display: flex;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    display: none;
  }
`;
