import React from 'react';
import styled from 'styled-components';
import { Col, Container, fontInter, H3, Row, Text } from 'ui';
import { BREAKPOINTS } from 'utils';
import bg from 'assets/info/bg.png';
import icon from 'assets/info/icon.png';
import icon2 from 'assets/info/icon2.png';
import icon3 from 'assets/info/icon3.png';
import icon4 from 'assets/info/icon4.png';

const items = [
  {
    img: icon,
    title: 'от 40 000',
    description: 'Машин в сутки'
  },
  {
    img: icon2,
    title: '100 %',
    description: 'Безопасность в любое время'
  },
  {
    img: icon3,
    title: '50 %',
    description: 'Экономии времени на дорогу'
  },
  {
    img: icon4,
    title: '0',
    description: 'Барьеров. Свободное движение'
  }
];

export const Info = (props) => {
  return (
    <Wrapper {...props} relative>
      <BG src={bg} />
      <CenterContainer maxWidth={1200}>
        <Title marginRight={15}>Лыткаринская платная дорога</Title>

        <StyledRow
          relative
          zIndex={20}
          direction={'column'}
          marginTop={100}
          mdMarginTop={60}
          smdMarginTop={48}
        >
          {items.map((item, index) => (
            <Row
              alignItems={'flex-start'}
              key={index}
              marginBottom={80}
              mdMarginBottom={50}
              smdMarginBottom={36}
            >
              <Icon src={item.img} alt='icon' />
              <Col marginLeft={24}>
                <SubTitle marginBottom={10} smdMarginBottom={5}>
                  {item.title}
                </SubTitle>
                <Description>{item.description}</Description>
              </Col>
            </Row>
          ))}
        </StyledRow>
      </CenterContainer>
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  background-color: #074a87;
  color: #fff;
`;

const CenterContainer = styled(Container)`
  margin-right: auto;
  margin-left: auto;
  @media (max-width: 1440px) {
    margin-left: 120px;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-left: 50px;
    margin-right: 50px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-left: 23px;
    margin-right: 23px;
  }
`;

const BG = styled.img`
  position: absolute;
  bottom: 114px;
  right: 0;
  z-index: 10;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    right: -295px;
    bottom: 84px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    height: 509px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    height: 266px;
    right: -190px;
    bottom: 158px;
  }
`;

const Title = styled(H3)`
  color: #fff;
`;

const StyledRow = styled(Row)`
  & div:last-child {
    margin-bottom: 0;
  }

  @media (max-width: ${BREAKPOINTS.smd}px) {
    & div:first-child img {
      max-width: 68px;
    }
    & div:nth-child(2) img {
      max-width: 44px;
    }
    & div:nth-child(3) img {
      max-width: 48px;
    }
    & div:last-child img {
      max-width: 50px;
    }
  }
`;

const Icon = styled.img``;

const SubTitle = styled(Text)`
  ${fontInter}
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 36px;
    line-height: 44px;
  }
`;

const Description = styled(Text)`
  font-size: 18px;
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 16px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    max-width: 185px;
  }
`;
