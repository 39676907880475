import burger from 'assets/common/menu_burger.svg';
import logo from 'assets/logo.svg';
import { Menu } from 'components';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Container, Img, InteractLink, MainContainer, Number, Row } from 'ui';
import { BREAKPOINTS, COLORS } from 'utils';

export const Header = ({ active = '' }) => {
  const [menuOpened, setMenuOpened] = useState(false);

  return (
    <StyledHeader>
      <HeaderMainContainer height={'100%'}>
        <MainRow
          alignCenter
          spaceBetween
          height={'100%'}
          nowrap
          active={active === 'about-project'}
        >
          <Link to={'/'}>
            <Logo src={logo} width={112} block alt='' />
          </Link>

          <NavContainer lgHidden>
            <Row gap={18}>
              <div>
                <StyledLink to='/' active={active === '/'}>
                  Главная
                </StyledLink>
              </div>

              <div>
                <StyledLink
                  to='/about-project'
                  active={active === 'about-project'}
                >
                  О проекте
                </StyledLink>
              </div>

              <div>
                <StyledLink to='/news' active={active === 'news'}>
                  Новости
                </StyledLink>
              </div>

              {/* <div>
                <StyledLink to='/building' active={active === 'building'}>
                  Ход строительства
                </StyledLink>
              </div> */}

              <div>
                <StyledLink to='/mass-media' active={active === 'mass-media'}>
                  СМИ о нас
                </StyledLink>
              </div>

              <div>
                <StyledLink to='/contacts' active={active === 'contacts'}>
                  Контакты
                </StyledLink>
              </div>
            </Row>
          </NavContainer>

          <div>
            <Row gap={32} smdGap={15} alignCenter nowrap>
              <div>
                <InteractLink>
                  <Number>+7 495 181 76 10</Number>
                </InteractLink>
              </div>
              <Container hidden lgVisible>
                <Burger
                  src={burger}
                  alt={''}
                  width={39}
                  onClick={() => setMenuOpened(true)}
                />
              </Container>
            </Row>
          </div>
        </MainRow>
      </HeaderMainContainer>

      <Menu opened={menuOpened} onClose={() => setMenuOpened(false)} />
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  height: 126px;
  display: flex;
  align-items: center;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);

  @media (max-width: ${BREAKPOINTS.lg}px) {
    height: 108px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    height: 51px;
  }
`;
const HeaderMainContainer = styled(MainContainer)`
  @media (max-width: ${BREAKPOINTS.xxl}px) {
    max-width: 1200px;
    padding: 0 15px;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    max-width: unset;
    padding-left: 50px;
    padding-right: 50px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    padding-left: 23px;
    padding-right: 23px;
  }
`;
const MainRow = styled(Row)`
  position: relative;

  :after {
    ${(p) =>
      p.active
        ? ''
        : `
    content: '';
    position: absolute;
    right: 0;
    bottom: -12px;
    height: 12px;
    width: 170px;
    background: #54ccc9;
    transform: skew(-33deg);
    `}
  }

  @media (max-width: ${BREAKPOINTS.lg}px) {
    :after {
      right: 97px;
    }
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    :after {
      content: none;
      right: 34px;
      width: 80px;
    }
  }
`;
const NavContainer = styled(Container)``;
const Logo = styled(Img)`
  @media (max-width: ${BREAKPOINTS.smd}px) {
    width: 61px;
  }
`;
const Burger = styled(Img)`
  @media (max-width: ${BREAKPOINTS.smd}px) {
    width: 18px;
  }
`;
const StyledLink = styled(Link)`
  font-size: 18px;
  text-decoration: none;
  color: ${COLORS.black};

  ${(p) =>
    p.active
      ? `
      font-weight:600;
      color: ${COLORS.blueActive}
    `
      : ''}
`;
