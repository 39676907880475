import React from 'react';
import styled from 'styled-components';
import { Header, Footer, Navigation } from 'components';
import { Col, Container, H3, MainContainer, Row } from 'ui';
import { BREAKPOINTS } from 'utils';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

export const ContactsPage = ({ page }) => {
  return (
    <>
      <Header active={page} />
      <Wrapper>
        <MainContainer>
          <Container marginBottom={43} lgMarginBottom={31} smdHidden>
            <Navigation page={page}></Navigation>
          </Container>

          <StyledH3>Контакты</StyledH3>

          <Row
            marginTop={60}
            lgMarginTop={48}
            smdMarginTop={36}
            spaceBetween
            alignCenter
          >
            <Col width={'465px'} paddingLeft={3}>
              <TextBold>Телефон</TextBold>
              <TextNormal marginBottom={36} smdMarginBottom={24}>
                +7-495-181-76-10
              </TextNormal>
              <TextBold>Почта</TextBold>
              <TextNormal marginBottom={36} smdMarginBottom={24}>
                office@ltkz.ru
              </TextNormal>
              <TextBold>Адрес</TextBold>
              <TextNormal marginBottom={36} smdMarginBottom={24}>
                140005, Московская области,
                <br />
                г. Люберцы, ул. Комсомольская, д. 15А,
                <br />
                этаж 16, помещение 31, комната 5
              </TextNormal>
            </Col>
            <Block width={'50%'} lgDisplay={'none'} alignSelf={'center'}>
              <YMaps>
                <Map
                  width={'100%'}
                  height={'483px'}
                  defaultState={{ center: [55.677118, 37.882243], zoom: 16 }}
                >
                  <Placemark defaultGeometry={[55.677118, 37.882243]} />
                </Map>
              </YMaps>
            </Block>
          </Row>
        </MainContainer>
        <Block
          width={'100%'}
          display={'none'}
          lgDisplay={'block'}
          alignSelf={'center'}
        >
          <YMaps>
            <Map
              width={'100%'}
              height={'483px'}
              defaultState={{ center: [55.677118, 37.882243], zoom: 17 }}
            >
              <Placemark defaultGeometry={[55.677118, 37.882243]} />
            </Map>
          </YMaps>{' '}
        </Block>
      </Wrapper>
      <Footer active={page} />
    </>
  );
};

const Wrapper = styled.div`
  position: relative;
  padding-top: 63px;
  padding-bottom: 208px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    padding-bottom: 160px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    padding-top: 48px;
    padding-bottom: 96px;
  }
`;

const StyledH3 = styled(H3)`
  font-size: 40px;
`;

const TextBold = styled.p`
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

const TextNormal = styled.p`
  margin-bottom: ${(p) => (p.marginBottom ? p.marginBottom + 'px' : 'unset')};
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 18px;
    line-height: 23px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-bottom: ${(p) =>
      p.smdMarginBottom ? p.smdMarginBottom + 'px' : 'inherit'};
    font-size: 16px;
    line-height: 21px;
  }
`;

const Block = styled(Col)`
  display: ${(p) => (p.display ? p.display : 'inherit')};
  & .ymaps-2-1-79-map-copyrights-promo,
  .ymaps-2-1-79-copyright_logo_no {
    display: none;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    display: ${(p) => (p.lgDisplay ? p.lgDisplay : 'inherit')};
  }
`;
