import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Container, H3, Row, Text } from 'ui';

import arrow from 'assets/questions/arrow-down.svg';
import cancel from 'assets/questions/cancel.svg';
import { BREAKPOINTS, COLORS } from 'utils';

export const Questions = (props) => {
  const [select1, setSelect1] = useState(false);
  const [select2, setSelect2] = useState(false);
  const [select3, setSelect3] = useState(false);

  return (
    <Wrapper {...props}>
      <ContainerCenter maxWidth={1200}>
        <H3 marginBottom={40}>Часто задаваемые вопросы</H3>

        <Row direction={'column'} alignCenter>
          <Item
            wide
            maxWidth={984}
            marginBottom={20}
            paddingY={22}
            smdPaddingY={12}
            paddingX={30}
            lgPaddingX={20}
            smdPaddingX={10}
            select={select1}
            onClick={() => {
              setSelect1(!select1);
              setSelect2(false);
              setSelect3(false);
            }}
          >
            <Row nowrap wide spaceBetween>
              <StyledText>
                Для чего нужна Лыткаринская платная дорога?
              </StyledText>
              <Icon src={select1 ? cancel : arrow} alt={'dropdown'} />
            </Row>
          </Item>

          <Dropdown select={select1} maxWidth={870}>
            <Text marginBottom={20}>
              На ~10 км сократится перепробег и время в пути между двумя
              соседними городами Подмосковья (Дзержинский и Лыткарино).
            </Text>
            <Text marginBottom={20}>
              Новая трасса возьмет на себя часть трафика с федеральной трассы
              М-5 «Урал», увеличит скорость движения транспортных потоков и
              уменьшит количество пробок в несколько раз.
            </Text>
            <Text marginBottom={20}>
              Жители Лыткарино через Дзержинский смогут попадать в Люберцы,
              Котельники, Капотню и другие города Подмосковья / районы Москвы.
              Плюс появится альтернативный маршрут до станции метро Котельники.
            </Text>
            <Text marginBottom={30}>
              Из городов Лыткарино и Дзержинский появятся новые выезды на МКАД,
              минуя улицу Энергетиков и Новоегорьевское шоссе.
            </Text>
          </Dropdown>

          <Line />

          <Item
            wide
            maxWidth={984}
            marginY={20}
            paddingY={22}
            smdPaddingY={12}
            paddingX={30}
            lgPaddingX={20}
            smdPaddingX={10}
            select={select2}
            onClick={() => {
              setSelect2(!select2);
              setSelect1(false);
              setSelect3(false);
            }}
          >
            <Row nowrap wide spaceBetween>
              <StyledText>
                Какова стоимость проезда по Лыткаринской платной дороге?
              </StyledText>
              <Icon src={select2 ? cancel : arrow} alt={'dropdown'} />
            </Row>
          </Item>

          <Dropdown select={select2} maxWidth={870}>
            <Text marginBottom={20}>
              Стоимость проезда составит{' '}
              <span style={{ fontWeight: 600, color: COLORS.blueH }}>
                10 рублей за километр
              </span>
              .
            </Text>
            <Text marginBottom={20}>
              Для пользователей Южно-Лыткаринской автодороги взимание платы
              предусмотрено только за проезд по новым участкам строительства.
            </Text>
            <Text marginBottom={20}>
              Использование на платной основе существующей дорожной сети,
              попадающей в зону размещения ЮЛА и подлежащей модернизации, не
              планируется.
            </Text>
            <Text marginBottom={30}>
              Вместе с тем, концессией предусмотрена возможность бесплатного
              проезда для льготных категорий пользователей, в соответствии с
              действующим законодательством Российской Федерации.
            </Text>
          </Dropdown>

          <Line />

          <Item
            wide
            maxWidth={984}
            marginY={20}
            paddingY={22}
            smdPaddingY={12}
            paddingX={30}
            lgPaddingX={20}
            smdPaddingX={10}
            select={select3}
            onClick={() => {
              setSelect3(!select3);
              setSelect1(false);
              setSelect2(false);
            }}
          >
            <Row nowrap wide spaceBetween>
              <StyledText>
                Были учтены пожелания жителей при строительстве Лыткаринской
                платной дороги?
              </StyledText>
              <Icon src={select3 ? cancel : arrow} alt={'dropdown'} />
            </Row>
          </Item>

          <Dropdown select={select3} maxWidth={870}>
            <Text marginBottom={20}>
              В ходе проектирования дороги от инициативной группы жителей
              Ленинского округа поступил ряд предложений, большая часть которых
              была учтена и внесена в проектную документацию:
            </Text>
            <ul>
              <li>
                <Text marginBottom={20}>
                  Основной ход дороги в районе СНТ «Победа» и ЖК «Усадьба
                  Суханово» пройдет в заглублении – ниже уровня земли.
                </Text>
              </li>
              <li>
                <Text marginBottom={20}>
                  В районе 6 мкр-на г. Видное от д. № 8 по ул. Фокина до д. № 16
                  по ул. Завидная построят подземный тоннель. Это повысит
                  уровень безопасности движения и снизит уровень шума рядом с
                  домами.
                </Text>
              </li>
              <li>
                <Text>
                  Появится съезд с М-4 в районе пересечения Новолыткаринского
                  шоссе. Планируется обустройство съездов с шоссе на местную
                  дорогу в районе д. Ермолино для возможности выезда с ЮЛА на
                  М-4.
                </Text>
              </li>
              <li>
                <Text>
                  Дорога пройдет в обход усадьбы Суханово – в границах зоны
                  охраняемого природного ландшафта, с сохранением визуального
                  восприятия объекта культурного наследия.
                </Text>
              </li>
              <li>
                <Text>
                  Перенесен подземный переход на Расторгуевском шоссе между ЖК
                  «Видный город» и «Государев Дом», чтобы обеспечить равную
                  пешеходную доступность для местных жителей к соцобъектам.
                </Text>
              </li>
              <li>
                <Text>
                  В 12 раз сократили число изъятия земельных участков в районе
                  д. Орлово.
                </Text>
              </li>
              <li>
                <Text>
                  Исключили из проектных решений съезд к ЖК «Эко Видное», чтобы
                  избежать дополнительного транзита по территории жилой
                  застройки.
                </Text>
              </li>
              <li>
                <Text>
                  Сохранили в проекте линию скоростного трамвая (ЛРТ) в створе
                  ЮЛА – на перспективное развитие территории.
                </Text>
              </li>
            </ul>
          </Dropdown>
        </Row>
      </ContainerCenter>
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  background-color: #f9f9f9;
`;

const ContainerCenter = styled(Container)`
  margin: 0 auto;
`;

const Item = styled(Row)`
  cursor: pointer;
  ${({ select }) =>
    select &&
    css`
    font-weight: 600;
    color: #fff;
    background: rgba(16, 110, 183, 0.8);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    transition: .3s ease;
    }
    `}
  > * {
    transition: 0.3s ease;
  }
`;

const StyledText = styled(Text)`
  font-weight: 500;
`;

const Icon = styled.img`
  padding-left: 15px;
`;

const Line = styled.div`
  max-width: 984px;
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
`;

const Dropdown = styled(Container)`
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  display: none;
  ${({ select }) =>
    select &&
    css`
      display: block;
    `};

  @media (max-width: ${BREAKPOINTS.lg}px) {
    padding-left: 20px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    padding-left: 10px;
  }
`;
