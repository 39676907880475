import React, { useState } from 'react';
import styled from 'styled-components';
import {
  ArticlePreview,
  Footer,
  Header,
  Loader,
  Navigation,
  Pagination
} from 'components';
import { Container, H3, Row, Text } from 'ui';
import { COLORS } from 'utils';
import { useQuery } from '@apollo/client';
import { GET_ALL_ARTICLES, GET_ALL_BUILDINGS, GET_ALL_NEWS } from 'api/queries';

export const AllArticlesPage = ({ page }) => {
  const [currentSection, setCurrentSection] = useState('');
  const [active, setActive] = useState(1);
  const [limit, setLimit] = useState(5);

  if (currentSection !== page) {
    setCurrentSection(page);
    setActive(1);
  }

  let title = '';
  let query = '';
  switch (page) {
    case 'news':
      title = 'Новости компании';
      query = GET_ALL_NEWS;
      break;
    case 'building':
      title = 'Ход строительства';
      query = GET_ALL_BUILDINGS;
      break;
    case 'mass-media':
      title = 'СМИ о нас';
      query = GET_ALL_ARTICLES;
      break;
    default:
      query = null;
  }

  const response = useQuery(query, {
    variables: {
      start: (active - 1) * 5,
      limit: 5
    }
  });

  const responseMobile = useQuery(query, {
    variables: {
      start: 0,
      limit: 100
    }
  });

  if (response.loading) {
    return (
      <Row justifyContent={'center'} wide alignCenter height={'600px'}>
        <Loader />
      </Row>
    );
  } else if (response.error) {
    return <p>Something went wrong...</p>;
  }

  if (responseMobile.loading) {
    return (
      <Row justifyContent={'center'} wide alignCenter height={'600px'}>
        <Loader />
      </Row>
    );
  } else if (responseMobile.error) {
    return <p>Something went wrong...</p>;
  }

  let section = '';

  let sectionMobile = '';

  switch (page) {
    case 'news':
      section = response.data.news?.data;
      sectionMobile = responseMobile.data.news?.data;
      break;
    case 'building':
      section = response.data.buildings?.data;
      sectionMobile = responseMobile.data.buildings?.data;
      break;
    case 'mass-media':
      section = response.data.articles?.data;
      sectionMobile = responseMobile.data.articles?.data;
      break;
    default:
      section = null;
      sectionMobile = null;
  }

  const showMore = () => {
    setLimit((prev) => prev + 5);
  };

  return (
    <>
      <Header active={page} />

      <Wrapper
        maxWidth={1200}
        paddingX={15}
        lgPaddingX={50}
        smdPaddingX={23}
        paddingTop={63}
        paddingBottom={240}
      >
        <Container marginBottom={52} smdHidden>
          <Navigation page={page} />
        </Container>

        <StyledH3 marginBottom={60}>{title}</StyledH3>

        <Container marginBottom={120} lgMarginBottom={80} smdHidden>
          {section.map((item, index) =>
            index !== section.length - 1 ? (
              <div key={index}>
                <ArticlePreview
                  data={{ id: item.id, ...item?.attributes }}
                  page={page}
                />
                <Line marginY={30} />
              </div>
            ) : (
              <ArticlePreview
                key={index}
                data={{ id: item.id, ...item?.attributes }}
                page={page}
              />
            )
          )}
        </Container>

        <ContainerMobile hidden smdVisible smdMarginBottom={36}>
          {sectionMobile.slice(0, limit).map((item, index) =>
            index === 0 ? (
              <div key={index}>
                <ArticlePreview
                  data={{ id: item.id, ...item?.attributes }}
                  page={page}
                />
              </div>
            ) : (
              <div key={index}>
                <Line marginY={30} />
                <ArticlePreview
                  data={{ id: item.id, ...item?.attributes }}
                  page={page}
                />
              </div>
            )
          )}
        </ContainerMobile>

        <Row justifyContent={'center'}>
          <Pagination active={active} changeActive={setActive} page={page} />
        </Row>

        <ContainerMobile hidden smdVisible>
          {limit > sectionMobile.length ? null : (
            <StyledText onClick={() => showMore()}>Смотреть еще</StyledText>
          )}
        </ContainerMobile>
      </Wrapper>
      <Footer active={page} />
    </>
  );
};

const Wrapper = styled(Container)`
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 750px) {
    padding-right: 15px;
    padding-left: 15px;
  }
`;

const ContainerMobile = styled(Container)``;

const StyledH3 = styled(H3)`
  font-size: 40px;
`;

const Line = styled(Container)`
  height: 1px;
  border-bottom: 1px solid #d9d8d8;
`;

const StyledText = styled(Text)`
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: ${COLORS.blueActive};
`;
