import React from 'react';
import styled from 'styled-components';
import { Col, Container, H3, Img, Row, Text } from 'ui';

import ill1 from 'assets/partnership/ill1.png';
import ill2 from 'assets/partnership/ill2.png';
import icon from 'assets/partnership/icon.png';
import icon2 from 'assets/partnership/icon2.png';
import icon3 from 'assets/partnership/icon3.png';
import icon4 from 'assets/partnership/icon4.png';
import { BREAKPOINTS } from 'utils';

export const Partnership = (props) => {
  return (
    <Wrapper {...props}>
      <H3
        marginX={120}
        lgMarginX={50}
        smdMarginX={23}
        marginBottom={60}
        smdMarginBottom={36}
      >
        Государственно-частное партнерство
      </H3>

      <ContainerDesktop paddingX={120} lgHidden>
        <Row maxWidth={1200} alignCenter marginBottom={40} nowrap>
          <Col width={'50%'} marginRight={60}>
            <Text marginBottom={40}>
              В настоящее время в России активно продвигается новая
              перспективная форма сотрудничества государства и бизнеса –
              государственно-частное партнерство (ГЧП). ГЧП способствует:
            </Text>

            <Row>
              <Row nowrap alignCenter marginBottom={40}>
                <Icon src={icon} marginRight={20} />
                <Text>
                  Привлечению дополнительного финансирования для реализации
                  важнейших инфраструктурных проектов.
                </Text>
              </Row>

              <Row nowrap alignCenter>
                <Icon src={icon2} marginRight={20} />
                <Text>
                  Развитию улично-дорожной сети без увеличения бюджетных
                  расходов.
                </Text>
              </Row>
            </Row>
          </Col>

          <Col width={'50%'}>
            <Text marginBottom={40}>
              Основной плюс ГЧП состоит в том, что результаты сотрудничества
              государства и бизнеса может увидеть и ощутить на себе каждый. Так,
              реализация проектов ГЧП позволяет:
            </Text>

            <Row>
              <Row nowrap alignCenter marginBottom={40}>
                <Icon src={icon3} marginRight={20} />
                <Text>
                  Повысить качество оказания услуг и снизить затраты при
                  эксплуатации объектов инфраструктуры.
                </Text>
              </Row>

              <Row nowrap alignCenter>
                <Icon src={icon4} marginRight={20} />
                <Text>
                  Получить в государственную собственность объект при
                  минимальных затратах.
                </Text>
              </Row>
            </Row>
          </Col>
        </Row>

        <Row nowrap maxWidth={1200} alignCenter>
          <Col marginRight={60}>
            <Img src={ill1} alt='ill1' maxWidth={'570px'} />
          </Col>

          <Col>
            <Img src={ill2} alt='ill2' maxWidth={'570px'} />
          </Col>
        </Row>
      </ContainerDesktop>

      <ContainerTablet hidden lgVisible>
        <Row
          lgPaddingX={50}
          smdPaddingX={23}
          lgMarginBottom={40}
          mdMarginBottom={50}
          alignCenter
          direction={'column'}
        >
          <Col width={'100%'}>
            <Text lgMarginBottom={40} smdMarginBottom={24}>
              В настоящее время в России активно продвигается новая
              перспективная форма сотрудничества государства и бизнеса –
              государственно-частное партнерство (ГЧП). ГЧП способствует:
            </Text>

            <Row>
              <Row nowrap alignCenter lgMarginBottom={40}>
                <Icon src={icon} lgMarginRight={20} />
                <Text>
                  Привлечению дополнительного финансирования для реализации
                  важнейших инфраструктурных проектов.
                </Text>
              </Row>

              <Row nowrap alignCenter>
                <Icon src={icon2} lgMarginRight={20} />
                <Text>
                  Развитию улично-дорожной сети без увеличения бюджетных
                  расходов.
                </Text>
              </Row>
            </Row>
          </Col>
        </Row>

        <Row justifyContent={'center'} lgMarginBottom={50}>
          <Img src={ill1} wide alt='ill' />
        </Row>

        <Row
          lgMarginBottom={50}
          lgPaddingX={50}
          smdPaddingX={23}
          alignCenter
          direction={'column'}
        >
          <Col width={'100%'}>
            <Text marginBottom={40} smdMarginBottom={24}>
              Основной плюс ГЧП состоит в том, что результаты сотрудничества
              государства и бизнеса может увидеть и ощутить на себе каждый. Так,
              реализация проектов ГЧП позволяет:
            </Text>

            <Row>
              <Row nowrap alignCenter lgMarginBottom={40}>
                <Icon src={icon3} lgMarginRight={20} />
                <Text>
                  Повысить качество оказания услуг и снизить затраты при
                  эксплуатации объектов инфраструктуры.
                </Text>
              </Row>

              <Row nowrap alignCenter>
                <Icon src={icon4} lgMarginRight={20} />
                <Text>
                  Получить в государственную собственность объект при
                  минимальных затратах.
                </Text>
              </Row>
            </Row>
          </Col>
        </Row>

        <Row justifyContent={'center'}>
          <Img src={ill2} wide alt='ill' />
        </Row>
      </ContainerTablet>
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
`;

const ContainerDesktop = styled(Container)``;

const ContainerTablet = styled(Container)``;

const Icon = styled(Img)`
  @media (max-width: ${BREAKPOINTS.smd}px) {
    max-width: 54px;
  }
`;
