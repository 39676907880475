import React from 'react';
import styled from 'styled-components';
import { Col, Container, Img, Number, PageContainer, Row, Text } from 'ui';
import { Button } from 'ui/components';
import ill1 from 'assets/learnMore/ill1.png';
import ill2 from 'assets/learnMore/ill2.png';
import ill3 from 'assets/learnMore/ill3.png';
import { BREAKPOINTS } from 'utils';
import { Link } from 'react-router-dom';

export const LearnMore = (props) => {
  return (
    <Wrapper {...props}>
      <PageContainer lgHidden maxWidth={1200} paddingX={20}>
        <Row direction={'row'} nowrap spaceBetween>
          <Col width={'427px'} marginBottom={60}>
            <p>
              Скоростная дорога (предельная скорость 120 км/ч) длиной около 45
              километров пройдет по территории Ленинского городского округа
              (ЛГО), а также городских округов Лыткарино и Люберцы, соединив
              Варшавское и Носовихинское шоссе. Автомагистраль станет ближайшим
              дублером МКАД и будет способствовать ее разгрузке.
            </p>
          </Col>
          <Row gap={30}>
            <Col auto marginBottom={32}>
              <StyledTitle marginBottom={20}>
                <Number>до 25 %</Number>
              </StyledTitle>

              <StyledText>
                Расчетная разгрузка МКАД в зоне прохождения дороги-дублера
              </StyledText>
            </Col>

            <Col auto>
              <StyledTitle marginBottom={20}>
                <Number>до 50 %</Number>
              </StyledTitle>

              <StyledText>
                Увеличение скорости передвижения между населенными пунктами
              </StyledText>
            </Col>
          </Row>
        </Row>

        <Row justifyContent='center' marginBottom={80}>
          <Link to='/about-project'>
            <Button title='Узнать подробнее' />
          </Link>
        </Row>
      </PageContainer>

      <TabletContainer
        hidden
        lgVisible
        lgMarginBottom={80}
        lgPaddingX={20}
        smdPaddingX={23}
        smdMarginBottom={40}
      >
        <StyledRow direction={'row'} nowrap justifyContent={'center'}>
          <Col width={'337px'} lgMarginRight={100} mdMarginRight={70}>
            <Container lgMarginBottom={40} smdMarginBottom={36}>
              <p>
                Скоростная дорога (предельная скорость 120 км/ч) длиной около 45
                километров пройдет по территории Ленинского городского округа
                (ЛГО), а также городских округов Лыткарино и Люберцы, соединив
                Варшавское и Носовихинское шоссе. Автомагистраль станет
                ближайшим дублером МКАД и будет способствовать ее разгрузке.
              </p>
            </Container>

            <TabletButton smdHidden>
              <Link to='/about-project'>
                <Button title='Узнать подробнее' />
              </Link>
            </TabletButton>
          </Col>

          <Row direction={'column'}>
            <Col auto marginBottom={26} mdMarginBottom={40}>
              <StyledTitle
                marginBottom={20}
                mdMarginBottom={10}
                smdMarginBottom={5}
              >
                <Number>до 25 %</Number>
              </StyledTitle>

              <StyledText>
                Расчетная разгрузка МКАД в зоне прохождения дороги-дублера
              </StyledText>
            </Col>

            <Col auto>
              <StyledTitle
                marginBottom={20}
                mdMarginBottom={10}
                smdMarginBottom={5}
              >
                <Number>до 50 %</Number>
              </StyledTitle>

              <StyledText>
                Увеличение скорости передвижения между населенными пунктами
              </StyledText>
            </Col>
          </Row>
        </StyledRow>
      </TabletContainer>

      <MobileButton hidden smdVisible smdMarginBottom={48} smdPaddingX={23}>
        <Link to='/about-project'>
          <Button title='Узнать подробнее' />
        </Link>
      </MobileButton>

      <ContainerIllustration>
        <Row nowrap>
          <StyledCol
            marginRight={30}
            lgMarginRight={19}
            maxWidth={'460px'}
            smdMarginRight={1}
          >
            <Img wide src={ill1} />
          </StyledCol>
          <Col marginRight={30} lgMarginRight={19} maxWidth={'460px'} smdHidden>
            <Img wide src={ill2} />
          </Col>
          <Col maxWidth={'460px'} smdHidden>
            <Img wide src={ill3} />
          </Col>
        </Row>
      </ContainerIllustration>
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  background: linear-gradient(
    180deg,
    #f0f7fb 0%,
    rgba(240, 247, 251, 0.8) 66.24%,
    rgba(240, 247, 251, 0) 100%
  );
`;

const StyledTitle = styled(Text)`
  font-weight: 600;
  font-size: 36px;
  line-height: 47px;
  color: #01509f;
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 24px;
    line-height: 31px;
  }
`;

const StyledText = styled(Text)`
  max-width: 240px;
  @media (max-width: ${BREAKPOINTS.smd}px) {
    max-width: 260px;
    font-size: 18px;
    line-height: 23px;
  }
`;

const TabletContainer = styled(Container)``;

const StyledRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.smd}px) {
    flex-direction: column;
  }
`;

const TabletButton = styled(Container)``;

const StyledCol = styled(Col)`
  @media (max-width: ${BREAKPOINTS.smd}px) {
    max-width: unset;
  }
`;

const MobileButton = styled(Container)``;

const ContainerIllustration = styled(Container)`
  max-width: 1440px;
  margin: 0 auto;
`;
