import React from 'react';
import styled from 'styled-components';
import bg from 'assets/aboutProjectPage/bg.jpg';
import shadow from 'assets/aboutProjectPage/shadow.png';
import { BREAKPOINTS } from 'utils';
import { Row, fontMagra, fontMontserrat } from 'ui';

export const MainScreen = ({ page }) => {
  return (
    <>
      <BG>
        <Shadow>
          <StyledRow
            paddingX={16}
            height={'620px'}
            direction={'column'}
            wide
            alignCenter
            justifyContent={'center'}
          >
            <Title>Лыткаринская платная дорога</Title>
            <Description>
              Высокоскоростная автомобильная магистраль категории I-Б,
              проходящая через несколько городских округов Подмосковья —
              Ленинский, Лыткарино, Люберцы. Население которых по официальным
              данным составляет свыше 900 тысяч человек
            </Description>
          </StyledRow>
        </Shadow>
      </BG>
    </>
  );
};

const BG = styled.div`
  width: 100%;
  height: 620px;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: ${BREAKPOINTS.smd}px) {
    height: 384px;
  }
`;

const Shadow = styled(BG)`
  height: 621px;
  background-image: url(${shadow});
  @media (max-width: ${BREAKPOINTS.md}px) {
    background-position: center;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    height: 385px;
  }
`;

const StyledRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.smd}px) {
    height: 384px;
  }
`;

const Title = styled.h2`
  max-width: 790px;
  ${fontMagra};
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  color: #ffffff;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 36px;
    line-height: 44px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

const Description = styled.p`
  max-width: 790px;
  margin-top: 24px;
  ${fontMontserrat};
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
`;
