import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Col, Container, fontMagra, Img, Row, Text } from 'ui';
import { BREAKPOINTS, COLORS, getImageUrl } from 'utils';

import { Date } from '../Date';
import { Source } from '../Source';

export const ArticlePreview = ({ data, page }) => {
  const imgUrl = getImageUrl(data.img?.data?.attributes?.url);

  let section = '';

  switch (page) {
    case 'mass-media':
      section = 'mass-media';
      break;
    case 'news':
      section = 'news';
      break;
    case 'building':
      section = 'building';
      break;
    default:
      section = null;
  }

  let title = data.title;
  if (data.title.length > 60) title = `${data.title.substr(0, 60)}...`;

  if (section)
    return (
      <Wrapper>
        <DesktopContainer smdHidden>
          <Row nowrap>
            <Col maxWidth={792} marginRight={24} lgMarginRight={30}>
              <Title marginBottom={20}>
                <StyledLink to={`/${section}/${data.id}`}>{title}</StyledLink>
              </Title>

              <Description marginBottom={42}>{data.description}</Description>

              <Row nowrap gap={12}>
                <Date date={data.date} />
                {data.link && <Source link={data.link} />}
              </Row>
            </Col>

            <Col>
              <StyledImg src={imgUrl} maxWidth={'351px'} />
            </Col>
          </Row>
        </DesktopContainer>

        <MobileContainer hidden smdVisible>
          <Title smdMarginBottom={10}>
            <StyledLink to={`/${section}/${data.id}`}>{data.title}</StyledLink>
          </Title>

          <Row nowrap gap={12} smdMarginBottom={16}>
            <Date date={data.date} />
            {data.link && <Source link={data.link} />}
          </Row>

          <Description smdMarginBottom={16}>{data.description}</Description>
          <Img src={imgUrl} wide />
        </MobileContainer>
      </Wrapper>
    );
};

const Wrapper = styled(Container)``;

const DesktopContainer = styled(Container)``;

const MobileContainer = styled(Container)``;

const Title = styled(Text)`
  ${fontMagra}
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  text-transform: uppercase;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 24px;
    line-height: 29px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

const Description = styled(Text)``;

const StyledImg = styled(Img)`
  height: 220px;
  object-fit: cover;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 245px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${COLORS.default};
`;
