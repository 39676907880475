import React from 'react';
import styled from 'styled-components';
import { Col, Container, H3, Img, Row, Text } from 'ui';
import { BREAKPOINTS } from 'utils';

import ill from 'assets/ecology/ill.jpg';
import icon from 'assets/ecology/icon1.svg';
import icon2 from 'assets/ecology/icon2.svg';
import icon3 from 'assets/ecology/icon3.svg';
import icon4 from 'assets/ecology/icon4.svg';

export const Ecology = (props) => {
  return (
    <Wrapper {...props}>
      <ContainerCenter maxWidth={1440}>
        <H3
          marginX={120}
          lgMarginX={50}
          smdMarginX={23}
          marginBottom={60}
          smdMarginBottom={36}
        >
          Экология проекта
        </H3>

        <ContainerDesktop paddingX={120} lgHidden relative>
          <Row
            gap={30}
            maxWidth={1265}
            marginBottom={40}
            mdMarginBottom={50}
            lgDirection={'column'}
          >
            <Col width={'50%'}>
              <Text marginBottom={40}>
                ООО «Лыткаринская платная дорога» ведет экологически
                ответственный бизнес, целью которого является максимально
                возможное снижение негативного влияния на окружающую среду.
              </Text>

              <Text marginBottom={40}>
                Мы внимательно относимся к вопросам экологии, осознавая
                ответственность перед будущими поколениями. Поэтому при
                производстве строительно-монтажных работ Компания гарантирует:
              </Text>

              <Item
                icon={icon}
                text='Экономное потребление природных ресурсов.'
                marginBottom={0}
              />
            </Col>
            <Col width={'50%'}>
              <Item
                icon={icon2}
                text='Повторное использование строительных материалов и утилизацию
                отходов.'
              />
              <Item
                icon={icon3}
                text=' Организацию экологически безопасного производственного процесса
                и автотранспортных перевозок.'
              />
              <Item
                icon={icon4}
                text='Использование технологий производства, позволяющих значительно сократить выбросы СО2.'
              />
            </Col>
          </Row>
        </ContainerDesktop>

        <ContainerTablet
          hidden
          lgVisible
          relative
          lgPaddingX={50}
          smdPaddingX={23}
        >
          <Text lgMarginBottom={40} smdMarginBottom={24}>
            ООО «Лыткаринская платная дорога» ведет экологически ответственный
            бизнес, целью которого является максимально возможное снижение
            негативного влияния на окружающую среду.
          </Text>

          <Text lgMarginBottom={40} smdMarginBottom={24}>
            Мы внимательно относимся к вопросам экологии, осознавая
            ответственность перед будущими поколениями. Поэтому при
            производстве, строительно-монтажных работ Компания гарантирует:
          </Text>

          <Container>
            <Item
              icon={icon}
              text='Экономное потребление природных ресурсов.'
            />
            <Item
              icon={icon2}
              text='Повторное использование строительных материалов и утилизацию
                отходов.'
            />
            <Item
              icon={icon3}
              text=' Организацию экологически безопасного производственного процесса
                и автотранспортных перевозок.'
            />
            <Item
              icon={icon4}
              text='Использование технологии производства, позволяющие значительно
                сократить выбросы СО2.'
              marginBottom={0}
            />
          </Container>
        </ContainerTablet>
      </ContainerCenter>

      <BG
        src={ill}
        marginTop={-169}
        lgMarginTop={-80}
        smdMarginTop={-29}
        wide
      />
    </Wrapper>
  );
};

const Item = ({ icon, text, marginBottom }) => {
  return (
    <Row
      nowrap
      alignCenter
      marginBottom={40}
      style={{ marginBottom: marginBottom }}
    >
      <Icon src={icon} marginRight={20} />
      <Text>{text}</Text>
    </Row>
  );
};

const Wrapper = styled(Container)``;

const ContainerCenter = styled(Container)`
  margin-left: auto;
  margin-right: auto;
`;

const ContainerDesktop = styled(Container)``;

const ContainerTablet = styled(Container)``;

const Icon = styled(Img)`
  @media (max-width: ${BREAKPOINTS.smd}px) {
    max-width: 54px;
  }
`;

const BG = styled(Img)``;
