import styled from 'styled-components';
import { BREAKPOINTS } from '../../utils';

export const marginStyles = (p) => `
  ${
    p.marginTop || p.marginY ? `margin-top: ${p.marginTop || p.marginY}px;` : ''
  }
  ${
    p.marginBottom || p.marginY
      ? `margin-bottom: ${p.marginBottom || p.marginY}px;`
      : ''
  }
  ${
    p.marginLeft || p.marginX
      ? `margin-left: ${p.marginLeft || p.marginX}px;`
      : ''
  }
  ${
    p.marginRight || p.marginX
      ? `margin-right: ${p.marginRight || p.marginX}px;`
      : ''
  }
  @media (max-width: ${BREAKPOINTS.xl}px) {
    ${
      p.xlMarginTop || p.xlMarginY
        ? `margin-top: ${p.xlMarginTop || p.xlMarginY}px;`
        : ''
    }
    ${
      p.xlMarginBottom || p.xlMarginY
        ? `margin-bottom: ${p.xlMarginBottom || p.xlMarginY}px;`
        : ''
    }
    ${
      p.xlMarginLeft || p.xlMarginX
        ? `margin-left: ${p.xlMarginLeft || p.xlMarginX}px;`
        : ''
    }
    ${
      p.xlMarginRight || p.xlMarginX
        ? `margin-right: ${p.xlMarginRight || p.xlMarginX}px;`
        : ''
    }
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    ${
      p.lgMarginTop || p.lgMarginY
        ? `margin-top: ${p.lgMarginTop || p.lgMarginY}px;`
        : ''
    }
    ${
      p.lgMarginBottom || p.lgMarginY
        ? `margin-bottom: ${p.lgMarginBottom || p.lgMarginY}px;`
        : ''
    }
    ${
      p.lgMarginLeft || p.lgMarginX
        ? `margin-left: ${p.lgMarginLeft || p.lgMarginX}px;`
        : ''
    }
    ${
      p.lgMarginRight || p.lgMarginX
        ? `margin-right: ${p.lgMarginRight || p.lgMarginX}px;`
        : ''
    }
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    ${
      p.mdMarginTop || p.mdMarginY
        ? `margin-top: ${p.mdMarginTop || p.mdMarginY}px;`
        : ''
    }
    ${
      p.mdMarginBottom || p.mdMarginY
        ? `margin-bottom: ${p.mdMarginBottom || p.mdMarginY}px;`
        : ''
    }
    ${
      p.mdMarginLeft || p.mdMarginX
        ? `margin-left: ${p.mdMarginLeft || p.mdMarginX}px;`
        : ''
    }
    ${
      p.mdMarginRight || p.mdMarginX
        ? `margin-right: ${p.mdMarginRight || p.mdMarginX}px;`
        : ''
    }
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    ${
      p.smdMarginTop || p.smdMarginY
        ? `margin-top: ${p.smdMarginTop || p.smdMarginY}px;`
        : ''
    }
    ${
      p.smdMarginBottom || p.smdMarginY
        ? `margin-bottom: ${p.smdMarginBottom || p.smdMarginY}px;`
        : ''
    }
    ${
      p.smdMarginLeft || p.smdMarginX
        ? `margin-left: ${p.smdMarginLeft || p.smdMarginX}px;`
        : ''
    }
    ${
      p.smdMarginRight || p.smdMarginX
        ? `margin-right: ${p.smdMarginRight || p.smdMarginX}px;`
        : ''
    }
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${
      p.smMarginTop || p.smMarginY
        ? `margin-top: ${p.smMarginTop || p.smMarginY}px;`
        : ''
    }
    ${
      p.smMarginBottom || p.smMarginY
        ? `margin-bottom: ${p.smMarginBottom || p.smMarginY}px;`
        : ''
    }
    ${
      p.smMarginLeft || p.smMarginX
        ? `margin-left: ${p.smMarginLeft || p.smMarginX}px;`
        : ''
    }
    ${
      p.smMarginRight || p.smMarginX
        ? `margin-right: ${p.smMarginRight || p.smMarginX}px;`
        : ''
    }
  }
`;
export const paddingStyles = (p) => `
    ${
      p.paddingTop || p.paddingY
        ? `padding-top: ${p.paddingTop || p.paddingY}px;`
        : ''
    }
    ${
      p.paddingBottom || p.paddingY
        ? `padding-bottom: ${p.paddingBottom || p.paddingY}px;`
        : ''
    }
    ${
      p.paddingLeft || p.paddingX
        ? `padding-left: ${p.paddingLeft || p.paddingX}px;`
        : ''
    }
    ${
      p.paddingRight || p.paddingX
        ? `padding-right: ${p.paddingRight || p.paddingX}px;`
        : ''
    }
    @media (max-width: ${BREAKPOINTS.xl}px) {
      ${
        p.xlPaddingTop || p.xlPaddingY
          ? `padding-top: ${p.xlPaddingTop || p.xlPaddingY}px;`
          : ''
      }
      ${
        p.xlPaddingBottom || p.xlPaddingY
          ? `padding-bottom: ${p.xlPaddingBottom || p.xlPaddingY}px;`
          : ''
      }
      ${
        p.xlPaddingLeft || p.xlPaddingX
          ? `padding-left: ${p.xlPaddingLeft || p.xlPaddingX}px;`
          : ''
      }
      ${
        p.xlPaddingRight || p.xlPaddingX
          ? `padding-right: ${p.xlPaddingRight || p.xlPaddingX}px;`
          : ''
      }
    }
    @media (max-width: ${BREAKPOINTS.lg}px) {
      ${
        p.lgPaddingTop || p.lgPaddingY
          ? `padding-top: ${p.lgPaddingTop || p.lgPaddingY}px;`
          : ''
      }
      ${
        p.lgPaddingBottom || p.lgPaddingY
          ? `padding-bottom: ${p.lgPaddingBottom || p.lgPaddingY}px;`
          : ''
      }
      ${
        p.lgPaddingLeft || p.lgPaddingX
          ? `padding-left: ${p.lgPaddingLeft || p.lgPaddingX}px;`
          : ''
      }
      ${
        p.lgPaddingRight || p.lgPaddingX
          ? `padding-right: ${p.lgPaddingRight || p.lgPaddingX}px;`
          : ''
      }
    }
    @media (max-width: ${BREAKPOINTS.md}px) {
      ${
        p.mdPaddingTop || p.mdPaddingY
          ? `padding-top: ${p.mdPaddingTop || p.mdPaddingY}px;`
          : ''
      }
      ${
        p.mdPaddingBottom || p.mdPaddingY
          ? `padding-bottom: ${p.mdPaddingBottom || p.mdPaddingY}px;`
          : ''
      }
      ${
        p.mdPaddingLeft || p.mdPaddingX
          ? `padding-left: ${p.mdPaddingLeft || p.mdPaddingX}px;`
          : ''
      }
      ${
        p.mdPaddingRight || p.mdPaddingX
          ? `padding-right: ${p.mdPaddingRight || p.mdPaddingX}px;`
          : ''
      }
    }
     @media (max-width: ${BREAKPOINTS.smd}px) {
      ${
        p.smdPaddingTop || p.smdPaddingY
          ? `padding-top: ${p.smdPaddingTop || p.smdPaddingY}px;`
          : ''
      }
      ${
        p.smdPaddingBottom || p.smdPaddingY
          ? `padding-bottom: ${p.smdPaddingBottom || p.smdPaddingY}px;`
          : ''
      }
      ${
        p.smdPaddingLeft || p.smdPaddingX
          ? `padding-left: ${p.smdPaddingLeft || p.smdPaddingX}px;`
          : ''
      }
      ${
        p.smdPaddingRight || p.smdPaddingX
          ? `padding-right: ${p.smdPaddingRight || p.smdPaddingX}px;`
          : ''
      }
    }
    @media (max-width: ${BREAKPOINTS.sm}px) {
      ${
        p.smPaddingTop || p.smPaddingY
          ? `padding-top: ${p.smPaddingTop || p.smPaddingY}px;`
          : ''
      }
      ${
        p.smPaddingBottom || p.smPaddingY
          ? `padding-bottom: ${p.smPaddingBottom || p.smPaddingY}px;`
          : ''
      }
      ${
        p.smPaddingLeft || p.smPaddingX
          ? `padding-left: ${p.smPaddingLeft || p.smPaddingX}px;`
          : ''
      }
      ${
        p.smPaddingRight || p.smPaddingX
          ? `padding-right: ${p.smPaddingRight || p.smPaddingX}px;`
          : ''
      }
    }
    @media (max-width: ${BREAKPOINTS.xs}px) {
      ${
        p.xsPaddingTop || p.xsPaddingY
          ? `padding-top: ${p.xsPaddingTop || p.xsPaddingY}px;`
          : ''
      }
      ${
        p.xsPaddingBottom || p.xsPaddingY
          ? `padding-bottom: ${p.xsPaddingBottom || p.xsPaddingY}px;`
          : ''
      }
      ${
        p.xsPaddingLeft || p.xsPaddingX
          ? `padding-left: ${p.xsPaddingLeft || p.xsPaddingX}px;`
          : ''
      }
      ${
        p.xsPaddingRight || p.xsPaddingX
          ? `padding-right: ${p.xsPaddingRight || p.xsPaddingX}px;`
          : ''
      }
    }
`;

export const Container = styled.div`
  ${(p) => `
    ${marginStyles(p)}
    ${paddingStyles(p)}
    ${p.height ? `height: ${p.height};` : ''}
    ${p.minHeight ? `min-height: ${p.minHeight};` : ''}
    ${p.position || p.relative ? `position: ${p.position || 'relative'};` : ''}
    ${p.maxWidth ? `max-width: ${p.maxWidth}px;` : ''}
    ${p.textCenter ? `text-align: center;` : ''}
    ${p.zIndex ? `z-index: ${p.zIndex};` : ''}
    
    ${p.hidden ? 'display: none;' : ''}
    @media (max-width: ${BREAKPOINTS.xl}px) {
      ${p.xlHidden ? 'display: none;' : ''}
      ${p.xlVisible ? 'display: block;' : ''}
      ${p.xlMaxWidth ? `max-width: ${p.xlMaxWidth};` : ''}
      ${p.xlMinHeight ? `min-height: ${p.xlMinHeight};` : ''}
    }
    @media (max-width: ${BREAKPOINTS.lg}px) {
      ${p.lgHidden ? 'display: none;' : ''}
      ${p.lgVisible ? 'display: block;' : ''}
      ${p.lgTextCenter ? `text-align: center;` : ''}
      ${p.lgMaxWidth ? `max-width: ${p.lgMaxWidth}px;` : ''}
    }
    @media (max-width: ${BREAKPOINTS.md}px) {
      ${p.mdHidden ? 'display: none;' : ''}
      ${p.mdVisible ? 'display: block;' : ''}
      ${p.mdMinHeight ? `min-height: ${p.mdMinHeight};` : ''}
    }
     @media (max-width: ${BREAKPOINTS.smd}px) {
      ${p.smdHidden ? 'display: none;' : ''}
      ${p.smdVisible ? 'display: block;' : ''}
      ${p.smdMaxWidth ? `max-width: ${p.smdMaxWidth};` : ''}
    }
    @media (max-width: ${BREAKPOINTS.sm}px) {
      ${p.smHidden ? 'display: none;' : ''}
      ${p.smVisible ? 'display: block;' : ''}
      ${p.smMaxWidth ? `max-width: ${p.smMaxWidth};` : ''}
      ${p.smStatic ? `position: static;` : ''}
    }
  `}
`;

export const PageContainer = styled(Container)`
  width: 100%;
  max-width: ${(p) => `${p.maxWidth ? `${p.maxWidth}px` : '1600px'}`};
  margin-right: auto;
  margin-left: auto;
`;
export const MainContainer = styled(Container)`
  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  ${(p) => (p.maxWidth ? `max-width: ${p.maxWidth}px;` : '')}
  ${(p) =>
    p.maxWidth ? `transform: translateX(${(p.maxWidth - 1200) / 2}px);` : ''}
    
    @media (max-width: ${BREAKPOINTS.xl}px) {
    max-width: 950px;
    transform: unset;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    max-width: ${(p) => (p.lgMaxWidth ? `${p.lgMaxWidth}px` : '700px')};
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    max-width: ${(p) => (p.mdMaxWidth ? `${p.mdMaxWidth}px` : '581px')};
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    max-width: ${(p) => (p.smdMaxWidth ? `${p.smdMaxWidth}px` : '410px')};
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    max-width: 369px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const Row = styled(Container)`
  display: flex;
  justify-content: ${(p) =>
    p.justifyContent || p.spaceBetween
      ? p.justifyContent || 'space-between'
      : 'unset'};
  align-items: ${(p) =>
    p.alignCenter || p.alignItems ? p.alignItems || 'center' : 'unset'};
  flex-direction: ${(p) => (p.direction ? p.direction : 'unset')};
  flex-wrap: ${(p) => (p.wrap || p.nowrap ? p.wrap || 'nowrap' : 'wrap')};
  margin-left: ${(p) => (p.gap ? `${-p.gap}px` : '0')};
  margin-right: ${(p) => (p.gap ? `${-p.gap}px` : '0')};
  width: ${(p) => (p.width || p.wide ? p.width || '100%' : 'auto')};

  ${(p) =>
    p.gap
      ? `
    > * {
      padding-left: ${p.gap}px;
      padding-right: ${p.gap}px;
    }
  `
      : ''};

  ${(p) =>
    p.cols
      ? `
    > * {
      width: ${100 / p.cols}%;
      max-width: ${100 / p.cols}%;
    }
  `
      : ''};

  ${(p) =>
    p.minCols
      ? `
    > * {
      max-width: ${100 / p.minCols}%;
    }
  `
      : ''};

  ${(p) =>
    p.colsMB
      ? `
     margin-bottom: -${p.colsMB}px;
    > * {
      margin-bottom: ${p.colsMB}px;
    }
  `
      : ''};

  @media (max-width: ${BREAKPOINTS.xl}px) {
    ${(p) =>
      p.xlGap
        ? `
     margin-left: ${-p.xlGap}px;
     margin-right: ${-p.xlGap}px;
      > * {
        padding-left: ${p.xlGap}px;
        padding-right: ${p.xlGap}px;
      }
     `
        : ''};
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    ${(p) =>
      p.lgGap
        ? `
     margin-left: ${-p.lgGap}px;
     margin-right: ${-p.lgGap}px;
      > * {
        padding-left: ${p.lgGap}px;
        padding-right: ${p.lgGap}px;
      }
     `
        : ''};
    ${(p) =>
      p.lgCols
        ? `
      > * {
        width: ${100 / p.lgCols}%;
        max-width: ${100 / p.lgCols}%;
      }
    `
        : ''};
    ${(p) =>
      p.lgColsMB
        ? `
       margin-bottom: -${p.lgColsMB}px;
       > * {
        margin-bottom: ${p.lgColsMB}px;
       }
    `
        : ''};
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    ${(p) => (p.smdWrap ? 'flex-wrap: wrap;' : '')}
    ${(p) =>
      p.smdGap
        ? `
     margin-left: ${-p.smdGap}px;
     margin-right: ${-p.smdGap}px;
      > * {
        padding-left: ${p.smdGap}px;
        padding-right: ${p.smdGap}px;
      }
     `
        : ''};
    ${(p) =>
      p.smdCols
        ? `
       > * {
         width: ${100 / p.smdCols}%;
         max-width: ${100 / p.smdCols}%;
       }
     `
        : ''};
    ${(p) =>
      p.smdJustifyContent ? `justify-content: ${p.smdJustifyContent}` : ''};
    ${(p) =>
      p.smdColsMB
        ? `
       margin-bottom: -${p.smdColsMB}px;
       > * {
        margin-bottom: ${p.smdColsMB}px;
       }
    `
        : ''};
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${(p) =>
      p.smGap
        ? `
     margin-left: ${-p.smGap}px;
     margin-right: ${-p.smGap}px;
      > * {
        padding-left: ${p.smGap}px;
        padding-right: ${p.smGap}px;
      }
     `
        : ''};

    ${(p) =>
      p.smColsMB
        ? `
       margin-bottom: -${p.smColsMB}px;
       > * {
        margin-bottom: ${p.smColsMB}px;
       }
    `
        : ''};
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    ${(p) =>
      p.xsGap
        ? `
     margin-left: ${-p.xsGap}px;
     margin-right: ${-p.xsGap}px;
      > * {
        padding-left: ${p.xsGap}px;
        padding-right: ${p.xsGap}px;
      }
     `
        : ''};
    ${(p) =>
      p.xsColsMB
        ? `
       margin-bottom: -${p.xsColsMB}px;
       > * {
        margin-bottom: ${p.xsColsMB}px;
       }
    `
        : ''};
  }
`;

export const Col = styled(Container)`
  flex-grow: ${({ auto, width }) => (auto || width ? 'unset' : '1')};
  ${(p) => (p.alignSelf ? `align-self: ${p.alignSelf};` : '')}
  ${(p) =>
    p.width || p.wide
      ? `
    width: 100%;
    max-width: ${p.width || 'unset'};
  `
      : ''}
  ${(p) =>
    p.maxWidth
      ? `
    width: 100%;
    max-width: ${p.maxWidth};
    box-sizing: content-box;
  `
      : ''}
  ${(p) =>
    p.maxWidthExt
      ? `
     width: ${p.width || '100%'};
     max-width: ${p.maxWidthExt};
     box-sizing: content-box;
  `
      : ''}
  ${(p) =>
    p.minWidth
      ? `
    min-width: ${p.minWidth};
    box-sizing: content-box;
    `
      : ''};
  ${(p) =>
    p.padding
      ? `
  padding-left: ${p.padding}px;
  padding-right: ${p.padding}px;
  `
      : ''};

  ${(p) =>
    p.paddingRight
      ? ` 
    padding-right: ${p.paddingRight}px;
  `
      : ``}
  ${(p) =>
    p.paddingLeft
      ? `
    padding-left: ${p.paddingLeft}px;
  `
      : ``};

  @media (max-width: ${BREAKPOINTS.xxl}px) {
    ${(p) =>
      p.xxlMaxWidth
        ? `
      width: 100%;
      max-width: ${p.xxlMaxWidth};
      box-sizing: content-box;
    `
        : ''}

    ${(p) => (p.xxlWidth ? `width: ${p.xxlWidth};` : '')}
  }
  @media (max-width: ${BREAKPOINTS.xl}px) {
    ${(p) =>
      p.xlWidth || p.xlMaxWidth
        ? `
      width: 100%;
      max-width: ${p.xlWidth || p.xlMaxWidth};
      `
        : ''}
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    ${(p) =>
      p.lgWidth || p.lgMaxWidth
        ? `
      width: 100%;
      max-width: ${p.lgWidth || p.lgMaxWidth};
      `
        : ''}
    ${(p) => (p.lgOrder ? `order: ${p.lgOrder};` : '')}
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    ${(p) =>
      p.mdWidth || p.mdMaxWidth
        ? `
      width: 100%;
      max-width: ${p.mdWidth || p.mdMaxWidth};
      `
        : ''}
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    ${(p) =>
      p.smdWidth || p.smdMaxWidth
        ? `
      width: 100%;
      max-width: ${p.smdWidth || p.smdMaxWidth};
      `
        : ''}
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${(p) =>
      p.smWidth || p.smMaxWidth
        ? `
      width: 100%;
      max-width: ${p.smWidth || p.smMaxWidth};
      `
        : ''}
  }
`;

export const Img = styled.img`
  display: block;
  ${(p) => (p.inline ? 'display: inline;' : '')}
  ${(p) => marginStyles(p)}
  ${(p) =>
    p.maxWidth || p.wide
      ? `
      width: 100%;
      max-width: ${p.maxWidth || 'unset'};
  `
      : ''}
`;
